import React from 'react'
import { TiDelete } from "react-icons/ti";
function Tablecolordetail(props) {

     function sumkg1() {
         let datakg1 = props.data.detailcolor;

        let kg2 = 0;
        for (let index = 0; index < datakg1.length; index++) {
            kg2 += parseFloat(datakg1[index].kg1)

        }
       
        if(datakg1.length >0){
              

            if (kg2 >= parseFloat(datakg1[0].Kg_params)) {
                kg2 = parseFloat(datakg1[0].Kg_params)
    
            } else {
                let data1 = kg2 - parseFloat(datakg1[0].Kg_params);
    
                if (data1.toFixed(3) == -0.001) {
                    kg2 = parseFloat(datakg1[0].Kg_params);
                }
    
            }
        }

        return kg2.toFixed(3)
    }

    function sumformulate() {
        let datakg1 = props.data.detailcolor;

        let formulates = 0;
        for (let index = 0; index < datakg1.length; index++) {
            formulates += parseFloat(datakg1[index].formulate)

        }
        if (datakg1.length > 0) {
            if (formulates >= 99.99) {
                formulates = 100;
            }

        }

        return formulates.toFixed(2)
    }


    return (
        <div className='row-page'>
            <div style={{ width: '100%' }}>
                <div style={{ width: '100%', backgroundColor: '#afafaf' }}>
                    <label style={{ fontSize: 16, marginLeft: 10 }}><b>{props.data.number} .  Color : </b></label>
                    <label style={{ fontSize: 16, marginLeft: 10 }}>{props.data.colorheader}</label>
                </div>
                <table style={{ width: '100%' }}>
                    <tr>
                        <th style={{ width: '20%' }}>Ink Name</th>
                        <th style={{ width: '20%' }}>Formulate (%)</th>
                        <th style={{ width: '20%' }}> {props.data.detailcolor.length > 0 && props.data.detailcolor[0].Kg_params}kg/1000</th>
                        {/* <th style={{ width: '20%' }}>100 kg/1000</th> */}

                    </tr>

                    {props.data.detailcolor.map((item, i) => (
                        <tr>
                            <td>{item.inkname} </td>
                            <td>{item.formulate}</td>
                            <td>{item.kg1}</td>
                            {/* <td>{item.kg2}</td> */}

                        </tr>
                    ))}

                    <tr>
                        <td><center><b>Total</b></center></td>
                        <td><b>{sumformulate() != 'NaN' && sumformulate()}</b></td>
                        <td><b>{sumkg1() != 'NaN' && sumkg1()}</b></td>
                        {/* <td><b>{sumkg2() != 'NaN' && sumkg2()}</b></td> */}
                    </tr>
                </table>
            </div>
            <div style={{ width: '25%', backgroundColor: '#eeeded' }}>
                <div style={{ backgroundColor: '#afafaf' }}>
                    <label><b>Remark</b></label>
                </div>
                <label>{props.data.remark}</label>
            </div>

            <hr></hr>
        </div>
    )
}

export default Tablecolordetail