import React, { useState, useEffect } from 'react'
import FetchApi from '../../customhooks/functionFetchApi';



function ViewProductionsyspartb({ journalId }) {
  const FetchApis = new FetchApi();

  const ojectdata = {
    packId: '',
    protype: '',
    sideitemnumber: '',
    sidename: '',
    sidewfw: '',
    com1itemnumber: '',
    com1name: '',
    com1wfw: '',
    com2itemnumber: '',
    com2name: '',
    com2wfw: '',
    journalId: journalId
  }

  const [prodcutExt, setProdcutExt] = useState({ ...ojectdata })
  const [productInt, setProductInt] = useState({ ...ojectdata })
  const [productSpray, setProductSpray] = useState({ ...ojectdata })
  const [productCopoer, setProductCopoer] = useState({ ...ojectdata })
  const [prodcutPro_lacq, setprodcutPro_lacq] = useState({ ...ojectdata })
  const [packtestcheckbox, setPacktestcheckbox] = useState([])
  const [packercheckbox, setPackercheckbox] = useState([])

  const [prodatahead, setProdatahead] = useState({ /// header
    sidetinext: '',
    sidetinint: '',
    com1tinext: '',
    com1tinint: '',
    com2tinext: '',
    com2tinint: '',
    activebom: "",
    auditdep: "",
    packtest: [],
    packer: [],
    refproduct: "",
    journalId: journalId
  })

  useEffect(() => {
    getproductlist()
    pakctestlist()

  }, [])



  function getproductlist() {
    FetchApis.FethcGet(`/packtest/getpacktestByjournalid/${journalId}`).then(res => {
      if (res.status == 200) {
       
        if (res.data.data.length > 0) {
          setProdatahead({ ...res.data.data[0] })
          var packtestData = res.data.data[0].packtest;
          var packerData = res.data.data[0].packer;

          var packsplice = packtestData.split(',')
        
          setPacktestcheckbox(packsplice)

          var packersplit = packerData.split(',')
          setPackercheckbox(packersplit)
        }

      }
    })
  }


  function pakctestlist() {
    FetchApis.FethcGet(`/productionsystem/getproductByjournalid/${journalId}`).then(res => {

      if (res.status == 200) {

        const prolist = res.data.data
        if (res.data.data.length > 0) {
          for (let index = 0; index < prolist.length; index++) {
            const datalist = prolist[index];

            if (datalist.protype == 'ext') {
              setProdcutExt(datalist)
            }

            if (datalist.protype == 'int') {
              setProductInt(datalist)
            }

            if (datalist.protype == 'spray') {
              setProductSpray(datalist)
            }
            if (datalist.protype == 'lacq') {
              setprodcutPro_lacq(datalist)
            }
            if (datalist.protype == 'copper') {
              setProductCopoer(datalist)
            }

          }
        }



      }
    })
  }

  return (
    <div>

      <table style={{ width: '100%' }}>

        <tr>
          <th>Tin-Coating</th>
          <th colspan="3">
            <div className='row-page'>
              <div style={{ width: '50%' }}>
                <label>Ext : {prodatahead.sidetinext}</label>
              </div>
              <div>
                <label>Int : {prodatahead.sidetinint}</label>
              </div>
            </div>
          </th>
          <th colspan="3" >
            <div className='row-page'>
              <div style={{ width: '50%' }}>
                <label>Ext : {prodatahead.com1tinext}</label>
              </div>
              <div>
                <label>Int : {prodatahead.com1tinint}</label>
              </div>
            </div>
          </th>
          <th colspan="3">
            <div className='row-page'>
              <div style={{ width: '50%' }}>
                <label>Ext : {prodatahead.com2tinext}</label>
              </div>
              <div>
                <label>Int : {prodatahead.com2tinint}</label>
              </div>
            </div>
          </th>
        </tr>

        <tr>
          <th style={{ with: '25%' }}><center>Production System</center></th>
          <th colspan="3" style={{ with: '25%' }}><center>Side Seams</center></th>
          <th colspan="3" style={{ with: '25%' }}><center>Compound</center></th>
          <th colspan="3" style={{ with: '25%' }}><center>Compound</center></th>
        </tr>
        <tr>
          <td>{ }</td>
          <td><b>Item Number</b></td>
          <td><b>Name</b></td>
          <td><b>WFW .(kg. / 1,000 pcs)</b></td>

          <td><b>Item Number</b></td>
          <td><b>Name</b></td>
          <td><b>WFW .(kg. / 1,000 pcs)</b></td>

          <td><b>Item Number</b></td>
          <td><b>Name</b></td>
          <td><b>WFW .(kg. / 1,000 pcs)</b></td>
        </tr>
        {/*ext*/}
        <tr>
          <td><label>Ext</label></td>
          <td>{prodcutExt.sideitemnumber}</td>
          <td>{prodcutExt.sidename}</td>
          <td>{prodcutExt.sidewfw}</td>

          <td>{prodcutExt.com1itemnumber}</td>
          <td>{prodcutExt.com1name}</td>
          <td>{prodcutExt.com1wfw}</td>

          <td>{prodcutExt.com2itemnumber}</td>
          <td>{prodcutExt.com2name}</td>
          <td>{prodcutExt.com2wfw}</td>
        </tr>

        {/*int*/}
        <tr>
          <td><label>Int</label></td>
          <td>{productInt.sideitemnumber}</td>
          <td>{productInt.sidename}</td>
          <td>{productInt.sidewfw}</td>


          <td>{productInt.com1itemnumber}</td>
          <td>{productInt.com1name}</td>
          <td>{productInt.com1wfw}</td>


          <td>{productInt.com2itemnumber}</td>
          <td>{productInt.com2name}</td>
          <td>{productInt.com2wfw}</td>
        </tr>
        {/*spray lacq*/}
        <tr>
          <td><label>spray lacq.</label></td>
          <td>{productSpray.sideitemnumber}</td>
          <td>{productSpray.sidename}</td>
          <td>{productSpray.sidewfw}</td>


          <td>{productSpray.com1itemnumber}</td>
          <td>{productSpray.com1name}</td>
          <td>{productSpray.com1wfw}</td>


          <td>{productSpray.com2itemnumber}</td>
          <td>{productSpray.com2name}</td>
          <td>{productSpray.com2wfw}</td>
        </tr>

        {/*dataCopoer*/}
        <tr>
          <td>
            <div>Copper wire (kg /1,000 pcs)</div>
          </td>
          <td>{productCopoer.sideitemnumber}</td>
          <td>{productCopoer.sidename}</td>
          <td>{productCopoer.sidewfw}</td>

          <td>{productCopoer.com1itemnumber}</td>
          <td>{productCopoer.com1name}</td>
          <td>{productCopoer.com1wfw}</td>

          <td>{productCopoer.com2itemnumber}</td>
          <td>{productCopoer.com2name}</td>
          <td>{productCopoer.com2wfw}</td>
        </tr>
        {/*Lacq Stemp*/}
        <tr>
          <td>
            <div>Lacq Stemp rivet (kg / 1,000 pcs)</div>
          </td>
          <td>{prodcutPro_lacq.sideitemnumber}</td>
          <td>{prodcutPro_lacq.sidename}</td>
          <td>{prodcutPro_lacq.sidewfw}</td>

          <td>{prodcutPro_lacq.com1itemnumber}</td>
          <td>{prodcutPro_lacq.com1name}</td>
          <td>{prodcutPro_lacq.com1wfw}</td>

          <td>{prodcutPro_lacq.com2itemnumber}</td>
          <td>{prodcutPro_lacq.com2name}</td>
          <td>{prodcutPro_lacq.com2wfw}</td>
        </tr>

        <tr>
          <td>Product Pack tds </td>
          <td>
            <div style={{ padding: 5 }}>
              <div><b>Pack test: </b></div>
            </div>
            <div style={{ padding: 5 }}>
              <div><b>Packer</b></div>
            </div>
          </td>
          <td>
            <div style={{ padding: 5 }}>
              <div className='row-page'> {packtestcheckbox.map((item, i) => (
                <div style={{ marginRight: 10, width: 100 }}>
                  {item!=""&&<div><input type='checkbox' checked={true} />{item}</div>}
                  
                </div>
              ))} </div>

            </div>

            <div style={{ padding: 5 }}>
              <div className='row-page'> {packercheckbox.map((item, i) => (
                <div style={{ marginRight: 10, width: 100 }}>
                  {item!=""&&<div><input type='checkbox' checked={true} />{item}</div>}
                </div>
              ))} </div>
            </div>

          </td>
          <td>
            {prodatahead.auditdep == '1' ?
              <div className='row-page'>
                <div><input type='checkbox' checked={true} /></div>
                <label>Audit Dep</label>
              </div> :
              <div className='row-page'>
                <div><input type='checkbox' /></div>
                <label>Audit Dep</label>
              </div>
            }

            {prodatahead.activebom == '1' ?
              <div className='row-page'>
                <div><input type='checkbox' checked={true} /></div>
                <label>Actuve Bom</label>
              </div> :
              <div className='row-page'>
                <div><input type='checkbox' /></div>
                <label>Actuve Bom</label>
              </div>
            }
          </td>
          <td colspan="6">
            <div>
              <label><b>Ref. Porduct</b></label>
            </div>
            <div>
              <label>
                {prodatahead.refproduct}
              </label>
            </div>
          </td>
        </tr>

      </table>
    </div>
  )
}

export default ViewProductionsyspartb