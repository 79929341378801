import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import FetchApi from '../../../customhooks/functionFetchApi';
import Showdetailjournal from '../../../form01/components/showdetailjournal';
import Actionapprove from '../../components/componentapprove/actionapprove';
import RdApproveState from './rdapproveState';
import AllDetailpartb from '../../components/allDetailpartb';

import Editproductionsystem from './editproductionsystem';
import RdEdit1stcoating from '../../components/componentrdnewpartb/rdeditcoating/rdEdit1stcoating';
import RdEdit2ndcoating from '../../components/componentrdnewpartb/rdeditcoating/rdEdit2ndcoating';
import RdEdit3rdcoating from '../../components/componentrdnewpartb/rdeditcoating/rdEdit3rdcoating';
import RdEdit4thcoating from '../../components/componentrdnewpartb/rdeditcoating/rdEdit4thcoating';
import RdEdit5thcoating from '../../components/componentrdnewpartb/rdeditcoating/rdEdit5thcoating';
import RdEdit6thcoating from '../../components/componentrdnewpartb/rdeditcoating/rdEdit6thcoating';
import RdEdit7thcoating from '../../components/componentrdnewpartb/rdeditcoating/rdEdit7thcoating';



const datalistbody = {
  sequence: "1st",
  type: "",
  side: "",
  color: "",
  fm: "",
  dfw: "",
  wfw: "",
  temp: "",
  speed: "",
  rubber: "",
  other: ""
}

function FormEditJournal({ jourId, codeid, handleShow }) {

  const FetchApis = new FetchApi()
  const [key, setKey] = useState('jouranl');

  const [coating1st, setCoating1st] = useState([]);
  const [coating2nd, setCoating2nd] = useState([]);
  const [coating3rd, setCoating3rd] = useState([]);
  const [coating4th, setCoating4th] = useState([]);
  const [coating5th, setCoating5th] = useState([]);
  const [coating6th, setCoating6th] = useState([]);
  const [coating7th, setCoating7th] = useState([]);
  const [onloading, setOnloading] = useState(false);

  useEffect(() => {
    heandle_checkPartB();
  }, [])

  async function heandle_checkPartB() {
    await FetchApis.FethcGet(`/technical/techniaclByJournalId/${jourId}`).then((res) => {

      if (res.data.data.length > 1) {
        const datat1st = res.data.data.find((item) => item.sequence == '1st');
        setCoating1st(datat1st);
        const data2nd = res.data.data.find((item) => item.sequence == '2nd');
        setCoating2nd(data2nd);
        const data3rd = res.data.data.find((item) => item.sequence == '3rd');
        setCoating3rd(data3rd);
        const data4th = res.data.data.find((item) => item.sequence == '4th');
        setCoating4th(data4th);
        const data5th = res.data.data.find((item) => item.sequence == '5th');
        setCoating5th(data5th);
        const data6th = res.data.data.find((item) => item.sequence == '6th');
        setCoating6th(data6th);
        const data7th = res.data.data.find((item) => item.sequence == '7th');
        setCoating7th(data7th);
      }
    })
  }

  async function reloading() {
    // heandle_checkPartB()
    setOnloading(true)
    setTimeout(() => {
      setOnloading(false)
    }, 3000);
  }

  return (
    <div className='form-body-page'>
      <form >
        <Tabs
          id="controlled-tab-example"
          activeKey={key}
          onSelect={(k) => setKey(k)}
          className="mb-3"
        >
          <Tab eventKey="jouranl" title="TECHNICAL DATA SHEET A" style={{ backgroundColor: 'aliceblue' }}>
            <Showdetailjournal id={jourId} />
          </Tab>
          <Tab eventKey="journaldetailpartb" title="TECHNICAL DATA SHEET B" style={{ backgroundColor: 'aliceblue' }}>

            {onloading == false && <AllDetailpartb journalId={jourId} />}
          </Tab>
          <Tab eventKey="tincoating" title="Production System" style={{ backgroundColor: 'aliceblue' }}>
            <Editproductionsystem journalId={jourId} />
          </Tab>
          <Tab eventKey="rdaddnew1stcoating" title="เพิ่มข้อมูล ( 1 ST )" style={{ backgroundColor: 'aliceblue' }}>
            {coating1st != "" && <RdEdit1stcoating coating1st={coating1st} reloading={reloading} />}
          </Tab>

          <Tab eventKey="rdaddnew2ndcoating" title="เพิ่มข้อมูล( 2 ND )" style={{ backgroundColor: 'aliceblue' }}>
            {coating2nd != "" && <RdEdit2ndcoating coating2nd={coating2nd} reloading={reloading} />}
          </Tab>
          <Tab eventKey="rdaddnew3rdcoating" title="เพิ่มข้อมูล( 3 RD )" style={{ backgroundColor: 'aliceblue' }}>
            {coating3rd != "" && <RdEdit3rdcoating coating3rd={coating3rd} reloading={reloading} />}
          </Tab>
          <Tab eventKey="rdaddnew4thcoating" title="เพิ่มข้อมูล( 4 th )" style={{ backgroundColor: 'aliceblue' }}>
            {coating4th != "" && <RdEdit4thcoating coating4th={coating4th} reloading={reloading} />}
          </Tab>
          <Tab eventKey="rdaddnew5thcoating" title="เพิ่มข้อมูล( 5 th )" style={{ backgroundColor: 'aliceblue' }}>
            {coating5th != "" && <RdEdit5thcoating coating5th={coating5th} />}
          </Tab>
          <Tab eventKey="rdaddnew6thcoating" title="เพิ่มข้อมูล( 6 th )" style={{ backgroundColor: 'aliceblue' }}>
            {coating6th != "" && <RdEdit6thcoating coating6th={coating6th} reloading={reloading} />}
          </Tab>
          <Tab eventKey="rdaddnew7thcoating" title="เพิ่มข้อมูล( 7 th )" style={{ backgroundColor: 'aliceblue' }}>
            {coating7th != "" && <RdEdit7thcoating coating7th={coating7th} reloading={reloading} />}
          </Tab>

        </Tabs>
      </form>
    </div>
  )
}


const Rdcreateeditpart = ({ datavalues, codeid, stateflow }) => {

  const values = [true, 'sm-down', 'md-down', 'lg-down', 'xl-down', 'xxl-down'];
  const [fullscreen, setFullscreen] = useState(true);
  const [show, setShow] = useState(false);

  function handleShow(value) {
    setShow(value);
  }

  function refunction(value) {
    console.log(value)
  }

  return (
    <div >
      <Button variant="warning" style={{ fontSize: 12 }} onClick={() => handleShow(true)} >แก้ไขเอกสาร</Button>
      <Modal show={show} fullscreen={fullscreen} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>แก้ไขข้อมูลเอกสาร ( Part B )</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='end-flex-row'>
            <RdApproveState idjournal={datavalues} stateflow={stateflow} />
            <Actionapprove journalId={datavalues}/>
          </div>
          <FormEditJournal jourId={datavalues} codeid={codeid} stateflow={stateflow} handleShow={handleShow} />
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default Rdcreateeditpart