import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import FetchApi from '../../customhooks/functionFetchApi';
import { Button } from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import ViewProductionsyspartb from './veiwproductionsyspartb';

const objectPartB = {
    tecbid: "",
    sequence: "",
    bodyside: "",
    bodycolor: "",
    bodyfm: "",
    bodydfw_min: "",
    bodydfw_max: "",
    bodywfw: "",
    bodytemp: "",
    bodyspeed: "",
    bodyrubber: "",
    topside: "",
    topcolor: "",
    topfm: "",
    topdfw_min: "",
    topdfw_max: "",
    topwfw: "",
    toptemp: "",
    topspeed: "",
    toprubber: "",
    bottomside: "",
    bottomcolor: "",
    bottomfm: "",
    bottomdfw_min: "",
    bottomdfw_max: "",
    bottomwfw: "",
    bottomtemp: "",
    bottomspeed: "",
    bottomrubber: "",
    otherdetail: "",
    journalId: "",
    tdscode: ""
}


const AllDetailpartb = ({ journalId }) => {

    const FetchApis = new FetchApi();
    const [key, setKey] = useState('partB');
    const [jouranldata, setJournaldata] = useState([]);

    const [coanting1st, setCoanting1st] = useState(objectPartB);
    const [coanting2nd, setCoanting2nd] = useState(objectPartB);
    const [coanting3rd, setCoanting3rd] = useState(objectPartB);
    const [coanting4th, setCoanting4th] = useState(objectPartB);
    const [coanting5th, setCoanting5th] = useState(objectPartB);
    const [coanting6th, setCoanting6th] = useState(objectPartB);
    const [coanting7th, setCoanting7th] = useState(objectPartB);

    const [checkdata, setCheckdata] = useState(false);

    useEffect(() => {

        loadigndatajourng()

    }, [])

    function loadigndatajourng(){
        FetchApis.FethcGet(`/technical/techniaclByJournalId/${journalId}`).then((res) => {

            if (res.status == 200) {
                if (res.data.data.length != 0) {
                    setCheckdata(true)
                    Coatinglistbytype(res.data.data)
                }
                datalistjournal()
            }
        })
    }


    function datalistjournal() {
        FetchApis.FethcGet(`/journal/journalbyId/${journalId}`).then((res) => {
            if (res) {
                setJournaldata(res.data.data[0]);
            }
        })
    }

    function Coatinglistbytype(datalist) {
        const coating1st = datalist.find((item) => item.sequence == '1st');
        setCoanting1st(coating1st);

        const coating2nd = datalist.find((item) => item.sequence == '2nd');
        setCoanting2nd(coating2nd);

        const coating3rd = datalist.find((item) => item.sequence == '3rd');
        setCoanting3rd(coating3rd);

        const coating4th = datalist.find((item) => item.sequence == '4th');
        setCoanting4th(coating4th);

        const coating5th = datalist.find((item) => item.sequence == '5th');
        setCoanting5th(coating5th);

        const coating6th = datalist.find((item) => item.sequence == '6th');
        setCoanting6th(coating6th);

        const coating7th = datalist.find((item) => item.sequence == '7th');
        setCoanting7th(coating7th);
    }

    return (
        <div>{checkdata == false ? <div>
            <div className='row-page-box'>
                ยังไม่มีข้อมูล Part B
            </div>

        </div> : <div style={{width:'100%'}}>
            <div className='end-flex-row'>
            </div>
            <div className='row-between-page' style={{ backgroundColor: '#CDCBC7', padding: 10, borderRadius: 5, width: '100%' }}>
                <div style={{ width: '70%' }}><b>TECHNICAL DATA SHEET PART B </b> </div>
                <div style={{ width: '20%' }}><b>Ref .MK {jouranldata.tdscode}</b></div>
                <div style={{ width: '10%' }}><b>Date : {jouranldata.createdate}</b></div>
            </div>


            <div >
                <Tabs
                    id="controlled-tab-example"
                    activeKey={key}
                    onSelect={(k) => setKey(k)}
                    className="mb-3"
                >
                    <Tab eventKey="partB" title="Technical data sheet part B">
                        <div style={{ width: '100%' }}>


                            <table style={{ width: '100%' }}>
                                <tr>
                                    <th style={{ width: '5%' }}><center>sequence</center></th>
                                    <th style={{ width: '20%' }}><center>Coating system</center></th>
                                    <th style={{ width: '15%' }}><center>Body</center></th>
                                    <th style={{ width: '15%' }}><center>Top end</center></th>
                                    <th style={{ width: '15%' }}><center>Bottom end</center></th>
                                    <th style={{ width: '20%' }}><center>Other</center></th>
                                </tr>
                                <tr>
                                    <td ><center><b> </b></center></td>
                                    <td > <b>Ref . FG</b></td>
                                    <td ><b>{coanting1st.rfFgbody}</b></td>
                                    <td ><b>{coanting1st.rfFgtop}</b></td>
                                    <td ><b>{coanting1st.rfFgbottom}</b></td>
                                    <div style={{ padding: 5 }}>{coanting1st.otherdetail}</div>
                                </tr>
                                <tr>
                                    <td ><center><b>1 st </b></center></td>
                                    <td >Side</td>
                                    <td >{coanting1st.bodyside}</td>
                                    <td >{coanting1st.topside}</td>
                                    <td >{coanting1st.bottomside}</td>
                                    <div style={{ padding: 5 }}>{coanting1st.otherdetail}</div>
                                </tr>
                                <tr>
                                    <td ></td>
                                    <td >Color shade</td>
                                    <td >{coanting1st.bodycolor}</td>
                                    <td >{coanting1st.topcolor}</td>
                                    <td >{coanting1st.bottomcolor}</td>
                                    {/* <td style={{ width: '20%' }}></td> */}
                                </tr>
                                <tr>
                                    <td ></td>
                                    <td >FM.code</td>
                                    <td >{coanting1st.bodyfm}</td>
                                    <td >{coanting1st.topfm}</td>
                                    <td >{coanting1st.bottomfm}</td>
                                    {/* <td style={{ width: '20%' }}></td> */}
                                </tr>
                                <tr>
                                    <td ></td>
                                    <td >DFW ( gsm ) / wfw (Kg / 1000 Sht)</td>
                                    <td >{coanting1st.bodydfw_min != "" && <div>{coanting1st.bodydfw_min} - {coanting1st.bodydfw_max} / {coanting1st.bodywfw}</div>}</td>
                                    <td >{coanting1st.topdfw_min != "" && <div>{coanting1st.topdfw_min} - {coanting1st.topdfw_max} / {coanting1st.topwfw}</div>}</td>
                                    <td >{coanting1st.bottomdfw_min != "" && <div>{coanting1st.bottomdfw_min} - {coanting1st.bottomdfw_max} / {coanting1st.bottomwfw}</div>}</td>

                                </tr>
                                <tr>
                                    <td ></td>
                                    <td >Temp ( C )</td>
                                    <td >{coanting1st.bodytemp}</td>
                                    <td >{coanting1st.toptemp}</td>
                                    <td >{coanting1st.bottomtemp}</td>
                                    {/* <td style={{ width: '20%' }}></td> */}
                                </tr>
                                <tr>
                                    <td ></td>
                                    <td >Speed ( sph) / No .Rubber</td>
                                    <td ><div>{coanting1st.bodyspeed != "" ? <div>{coanting1st.bodyspeed} / {coanting1st.bodyrubber}</div> : <div></div>}</div></td>
                                    <td ><div>{coanting1st.topspeed != "" ? <div>{coanting1st.topspeed} / {coanting1st.toprubber}</div> : <div></div>}</div></td>
                                    <td ><div>{coanting1st.bottomspeed != "" ? <div> {coanting1st.bottomspeed} / {coanting1st.bottomrubber}</div> : <div></div>} </div></td>
                                    {/* <td style={{ width: '20%' }}></td> */}
                                </tr>
                            </table>


                            <table style={{ width: '100%' }}>
                                <tr>
                                    <th style={{ width: '5%' }}><center>sequence</center></th>
                                    <th style={{ width: '20%' }}></th>
                                    <th style={{ width: '15%' }}></th>
                                    <th style={{ width: '15%' }}></th>
                                    <th style={{ width: '15%' }}></th>
                                    <th style={{ width: '20%' }}></th>
                                </tr>
                                <tr>
                                    <td ><center><b>2 nd  </b></center></td>
                                    <td >Side</td>
                                    <td >{coanting2nd.bodyside}</td>
                                    <td >{coanting2nd.topside}</td>
                                    <td >{coanting2nd.bottomside}</td>
                                    <div style={{ padding: 5 }}>{coanting2nd.otherdetail}</div>
                                </tr>
                                <tr>
                                    <td ><center><b></b></center></td>
                                    <td >Color shade</td>
                                    <td >{coanting2nd.bodycolor}</td>
                                    <td >{coanting2nd.topcolor}</td>
                                    <td >{coanting2nd.bottomcolor}</td>
                                </tr>
                                <tr>
                                    <td ></td>
                                    <td >FM.code</td>
                                    <td >{coanting2nd.bodyfm}</td>
                                    <td >{coanting2nd.topfm}</td>
                                    <td >{coanting2nd.bottomfm}</td>
                                </tr>
                                <tr>
                                    <td ></td>
                                    <td >DFW ( gsm ) / wfw (Kg / 1000 Sht)</td>
                                    <td >{coanting2nd.bodydfw_min != "" && <div>{coanting2nd.bodydfw_min} - {coanting2nd.bodydfw_max} / {coanting2nd.bodywfw}</div>}</td>
                                    <td >{coanting2nd.topdfw_min != "" && <div>{coanting2nd.topdfw_min} - {coanting2nd.topdfw_max} / {coanting2nd.topwfw}</div>}</td>
                                    <td >{coanting2nd.bottomdfw_min != "" && <div>{coanting2nd.bottomdfw_min} - {coanting2nd.bottomdfw_max} / {coanting2nd.bottomwfw}</div>}</td>

                                </tr>
                                <tr>
                                    <td ></td>
                                    <td >Temp ( C ) </td>
                                    <td >{coanting2nd.bodytemp}</td>
                                    <td >{coanting2nd.toptemp}</td>
                                    <td >{coanting2nd.bottomtemp}</td>
                                </tr>
                                <tr>
                                    <td ></td>
                                    <td >Speed ( sph) / No .Rubber</td>
                                    <td >{coanting2nd.bodyspeed != "" ? <div>{coanting2nd.bodyspeed} / {coanting2nd.bodyrubber}</div> : <div></div>}</td>
                                    <td >{coanting2nd.topspeed != "" ? <div>{coanting2nd.topspeed} / {coanting2nd.toprubber}</div> : <div></div>}</td>
                                    <td >{coanting2nd.bottomspeed != "" ? <div>{coanting2nd.bottomspeed} / {coanting2nd.bottomrubber}</div> : <div></div>}</td>
                                </tr>
                            </table>

                            <table style={{ width: '100%' }}>
                                <tr>
                                    <th style={{ width: '5%' }}><center>sequence</center></th>
                                    <th style={{ width: '20%' }}></th>
                                    <th style={{ width: '15%' }}></th>
                                    <th style={{ width: '15%' }}></th>
                                    <th style={{ width: '15%' }}></th>
                                    <th style={{ width: '20%' }}></th>
                                </tr>
                                <tr>
                                    <td ><center><b>3 rd  </b></center></td>
                                    <td >Side</td>
                                    <td >{coanting3rd.bodyside}</td>
                                    <td >{coanting3rd.topside}</td>
                                    <td >{coanting3rd.bottomside}</td>
                                    <div style={{ padding: 5 }}>{coanting3rd.otherdetail}</div>
                                </tr>
                                <tr>
                                    <td ></td>
                                    <td >Color shade</td>
                                    <td >{coanting3rd.bodycolor}</td>
                                    <td >{coanting3rd.topcolor}</td>
                                    <td >{coanting3rd.bottomcolor}</td>
                                </tr>
                                <tr>
                                    <td ></td>
                                    <td >FM.code</td>
                                    <td >{coanting3rd.bodyfm}</td>
                                    <td >{coanting3rd.topfm}</td>
                                    <td >{coanting3rd.bottomfm}</td>
                                </tr>
                                <tr>
                                    <td ></td>
                                    <td >DFW ( gsm ) / wfw (Kg / 1000 Sht)</td>
                                    <td >{coanting3rd.bodydfw_min != "" && <div>{coanting3rd.bodydfw_min} - {coanting3rd.bodydfw_max} / {coanting3rd.bodywfw}</div>}</td>
                                    <td >{coanting3rd.topdfw_min != "" && <div>{coanting3rd.topdfw_min} - {coanting3rd.topdfw_max} / {coanting3rd.topwfw}</div>}</td>
                                    <td >{coanting3rd.bottomdfw_min != "" && <div>{coanting3rd.bottomdfw_min} - {coanting3rd.bottomdfw_max} / {coanting3rd.bottomwfw}</div>}</td>
                                </tr>
                                <tr>
                                    <td ></td>
                                    <td >Temp ( C ) </td>
                                    <td >{coanting3rd.bodytemp}</td>
                                    <td >{coanting3rd.toptemp}</td>
                                    <td >{coanting3rd.bottomtemp}</td>
                                </tr>
                                <tr>
                                    <td ></td>
                                    <td >Speed ( sph) / No .Rubber</td>
                                    <td >{coanting3rd.bodyspeed != "" ? <div>{coanting3rd.bodyspeed} / {coanting3rd.bodyrubber}</div> : <div></div>}</td>
                                    <td >{coanting3rd.topspeed != "" ? <div>{coanting3rd.topspeed} / {coanting3rd.toprubber}</div> : <div></div>}</td>
                                    <td >{coanting3rd.bottomspeed != "" ? <div>{coanting3rd.bottomspeed} / {coanting3rd.bottomrubber}</div> : <div></div>}</td>
                                </tr>
                            </table>


                            <table style={{ width: '100%' }}>
                                <tr>
                                    <th style={{ width: '5%' }}><center>sequence</center></th>
                                    <th style={{ width: '20%' }}></th>
                                    <th style={{ width: '15%' }}></th>
                                    <th style={{ width: '15%' }}></th>
                                    <th style={{ width: '15%' }}></th>
                                    <th style={{ width: '20%' }}></th>
                                </tr>
                                <tr>
                                    <td ><center><b>4 th  </b></center></td>
                                    <td >Side</td>
                                    <td >{coanting4th.bodyside}</td>
                                    <td >{coanting4th.topside}</td>
                                    <td >{coanting4th.bottomside}</td>
                                    <div style={{ padding: 5 }}>{coanting4th.otherdetail}</div>
                                </tr>
                                <tr>
                                    <td ></td>
                                    <td >Color shade</td>
                                    <td >{coanting4th.bodycolor}</td>
                                    <td >{coanting4th.topcolor}</td>
                                    <td >{coanting4th.bottomcolor}</td>
                                </tr>
                                <tr>
                                    <td ></td>
                                    <td >FM.code</td>
                                    <td >{coanting4th.bodyfm}</td>
                                    <td >{coanting4th.topfm}</td>
                                    <td >{coanting4th.bottomfm}</td>
                                </tr>
                                <tr>
                                    <td ></td>
                                    <td >DFW ( gsm ) / wfw (Kg / 1000 Sht)</td>
                                    <td >{coanting4th.bodydfw_min != "" && <div>{coanting4th.bodydfw_min} - {coanting4th.bodydfw_max} / {coanting4th.bodywfw}</div>}</td>
                                    <td >{coanting4th.topdfw_min != "" && <div>{coanting4th.topdfw_min} - {coanting4th.topdfw_max} / {coanting4th.topwfw}</div>}</td>
                                    <td >{coanting4th.bottomdfw_min != "" && <div>{coanting4th.bottomdfw_min} - {coanting4th.bottomdfw_max} / {coanting4th.bottomwfw}</div>}</td>
                                </tr>
                                <tr>
                                    <td ></td>
                                    <td >Temp ( C )</td>
                                    <td >{coanting4th.bodytemp}</td>
                                    <td >{coanting4th.toptemp}</td>
                                    <td >{coanting4th.bottomtemp}</td>
                                </tr>
                                <tr>
                                    <td ></td>
                                    <td >Speed ( sph) / No .Rubber</td>
                                    <td >{coanting4th.bodyspeed != "" ? <div>{coanting4th.bodyspeed} / {coanting4th.bodyrubber}</div> : <div></div>}</td>
                                    <td >{coanting4th.topspeed != "" ? <div>{coanting4th.topspeed} / {coanting4th.toprubber}</div> : <div></div>}</td>
                                    <td >{coanting4th.bottomspeed != "" ? <div>{coanting4th.bottomspeed} /{coanting4th.bottomrubber}</div> : <div></div>}</td>
                                </tr>
                            </table>


                            <table style={{ width: '100%' }}>
                                <tr>
                                    <th style={{ width: '5%' }}><center>sequence</center></th>
                                    <th style={{ width: '20%' }}></th>
                                    <th style={{ width: '15%' }}></th>
                                    <th style={{ width: '15%' }}></th>
                                    <th style={{ width: '15%' }}></th>
                                    <th style={{ width: '20%' }}></th>
                                </tr>
                                <tr>
                                    <td ><center><b>5 th  </b></center></td>
                                    <td >Side</td>
                                    <td >{coanting5th.bodyside}</td>
                                    <td >{coanting5th.topside}</td>
                                    <td >{coanting5th.bottomside}</td>
                                    <div style={{ padding: 5 }}>{coanting5th.otherdetail}</div>
                                </tr>
                                <tr>
                                    <td ></td>
                                    <td >Color shade</td>
                                    <td >{coanting5th.bodycolor}</td>
                                    <td >{coanting5th.topcolor}</td>
                                    <td >{coanting5th.bottomcolor}</td>
                                </tr>
                                <tr>
                                    <td ></td>
                                    <td >FM.code</td>
                                    <td >{coanting5th.bodyfm}</td>
                                    <td >{coanting5th.topfm}</td>
                                    <td >{coanting5th.bottomfm}</td>
                                </tr>
                                <tr>
                                    <td ></td>
                                    <td >DFW ( gsm ) / wfw (Kg / 1000 Sht)</td>
                                    <td >{coanting5th.bodydfw_min != "" && <div>{coanting5th.bodydfw_min} - {coanting5th.bodydfw_max} / {coanting5th.bodywfw}</div>}</td>
                                    <td >{coanting5th.topdfw_min != "" && <div>{coanting5th.topdfw_min} - {coanting5th.topdfw_max} / {coanting5th.topwfw}</div>}</td>
                                    <td >{coanting5th.bottomdfw_min != "" && <div>{coanting5th.bottomdfw_min} - {coanting5th.bottomdfw_max} / {coanting5th.bottomwfw}</div>}</td>
                                </tr>
                                <tr>
                                    <td ></td>
                                    <td >Temp ( C ) </td>
                                    <td >{coanting5th.bodytemp}</td>
                                    <td >{coanting5th.toptemp}</td>
                                    <td >{coanting5th.bottomtemp}</td>
                                </tr>
                                <tr>
                                    <td ></td>
                                    <td >Speed ( sph) / No .Rubber</td>
                                    <td >{coanting5th.bodyspeed != "" ? <div>{coanting5th.bodyspeed} / {coanting5th.bodyrubber}</div> : <div></div>}</td>
                                    <td >{coanting5th.topspeed != "" ? <div>{coanting5th.topspeed} / {coanting5th.toprubber}</div> : <div></div>}</td>
                                    <td >{coanting5th.bottomspeed != "" ? <div>{coanting5th.bottomspeed} / {coanting5th.bottomrubber}</div> : <div></div>}</td>
                                </tr>
                            </table>


                            <table style={{ width: '100%' }}>
                                <tr>
                                    <th style={{ width: '5%' }}><center>sequence</center></th>
                                    <th style={{ width: '20%' }}></th>
                                    <th style={{ width: '15%' }}></th>
                                    <th style={{ width: '15%' }}></th>
                                    <th style={{ width: '15%' }}></th>
                                    <th style={{ width: '20%' }}></th>
                                </tr>
                                <tr>
                                    <td ><center><b>6 th  </b></center></td>
                                    <td >Side</td>
                                    <td >{coanting6th.bodyside}</td>
                                    <td >{coanting6th.topside}</td>
                                    <td >{coanting6th.bottomside}</td>
                                    <div style={{ padding: 5 }}>{coanting6th.otherdetail}</div>
                                </tr>
                                <tr>
                                    <td ></td>
                                    <td >Color shade</td>
                                    <td >{coanting6th.bodycolor}</td>
                                    <td >{coanting6th.topcolor}</td>
                                    <td >{coanting6th.bottomcolor}</td>
                                </tr>
                                <tr>
                                    <td ></td>
                                    <td >FM.code</td>
                                    <td >{coanting6th.bodyfm}</td>
                                    <td >{coanting6th.topfm}</td>
                                    <td >{coanting6th.bottomfm}</td>
                                </tr>
                                <tr>
                                    <td ></td>
                                    <td >DFW ( gsm ) / wfw (Kg / 1000 Sht)</td>
                                    <td >{coanting6th.bodydfw_min != "" && <div>{coanting6th.bodydfw_min} - {coanting6th.bodydfw_max} / {coanting6th.bodywfw}</div>}</td>
                                    <td >{coanting6th.topdfw_min != "" && <div>{coanting6th.topdfw_min} - {coanting6th.topdfw_max} / {coanting6th.topwfw}</div>}</td>
                                    <td >{coanting6th.bottomdfw_min != "" && <div>{coanting6th.bottomdfw_min} - {coanting6th.bottomdfw_max} / {coanting6th.bottomwfw}</div>}</td>
                                </tr>
                                <tr>
                                    <td ></td>
                                    <td >Temp ( C ) </td>
                                    <td >{coanting6th.bodytemp}</td>
                                    <td >{coanting6th.toptemp}</td>
                                    <td >{coanting6th.bottomtemp}</td>
                                </tr>
                                <tr>
                                    <td ></td>
                                    <td >Speed ( sph) / No .Rubber</td>
                                    <td >{coanting6th.bodyspeed != "" ? <div>{coanting6th.bodyspeed} / {coanting6th.bodyrubber}</div> : <div></div>}</td>
                                    <td >{coanting6th.topspeed != "" ? <div>{coanting6th.topspeed} / {coanting6th.toprubber}</div> : <div></div>}</td>
                                    <td >{coanting6th.bottomspeed != "" ? <div>{coanting6th.bottomspeed} / {coanting6th.bottomrubber}</div> : <div></div>}</td>
                                </tr>
                            </table>


                            <table style={{ width: '100%' }}>
                                <tr>
                                    <th style={{ width: '5%' }}><center>sequence</center></th>
                                    <th style={{ width: '20%' }}></th>
                                    <th style={{ width: '15%' }}></th>
                                    <th style={{ width: '15%' }}></th>
                                    <th style={{ width: '15%' }}></th>
                                    <th style={{ width: '20%' }}></th>
                                </tr>
                                <tr>
                                    <td ><center><b>7 th  </b></center></td>
                                    <td >Varnish top coat</td>
                                    <td >{coanting7th.bodyside}</td>
                                    <td >{coanting7th.topside}</td>
                                    <td >{coanting7th.bottomside}</td>
                                    <div >{coanting7th.otherdetail}</div>

                                </tr>
                                <tr>
                                    <td ></td>
                                    <td >Color shade</td>
                                    <td >{coanting7th.bodycolor}</td>
                                    <td >{coanting7th.topcolor}</td>
                                    <td >{coanting7th.bottomcolor}</td>
                                </tr>
                                <tr>
                                    <td ></td>
                                    <td >FM.code</td>
                                    <td >{coanting7th.bodyfm}</td>
                                    <td >{coanting7th.topfm}</td>
                                    <td >{coanting7th.bottomfm}</td>
                                </tr>
                                <tr>
                                    <td ></td>
                                    <td >DFW ( gsm ) / wfw (Kg / 1000 Sht)</td>
                                    <td >{coanting7th.bodydfw_min != "" && <div>{coanting7th.bodydfw_min} - {coanting7th.bodydfw_max} / {coanting7th.bodywfw}</div>}</td>
                                    <td >{coanting7th.topdfw_min != "" && <div>{coanting7th.topdfw_min} - {coanting7th.topdfw_max} / {coanting7th.topwfw}</div>}</td>
                                    <td >{coanting7th.bottomdfw_min != "" && <div>{coanting7th.bottomdfw_min} - {coanting7th.bottomdfw_max} / {coanting7th.bottomwfw}</div>}</td>
                                </tr>
                                <tr>
                                    <td ></td>
                                    <td >Temp ( C )</td>
                                    <td >{coanting7th.bodytemp}</td>
                                    <td >{coanting7th.toptemp}</td>
                                    <td >{coanting7th.bottomtemp}</td>
                                </tr>
                                <tr>
                                    <td ></td>
                                    <td >Speed ( sph) / No .Rubber</td>
                                    <td >{coanting7th.bodyspeed != "" ? <div>{coanting7th.bodyspeed} / {coanting7th.bodyrubber}</div> : <div></div>}</td>
                                    <td >{coanting7th.topspeed != "" ? <div>{coanting7th.topspeed} / {coanting7th.toprubber}</div> : <div></div>}</td>
                                    <td >{coanting7th.bottomspeed != "" ? <div>{coanting7th.bottomspeed} / {coanting7th.bottomrubber}</div> : <div></div>}</td>
                                </tr>
                            </table>
                            <ViewProductionsyspartb journalId={journalId}/>
                        </div>
                    </Tab>
                    {/* <Tab eventKey="printingsystem" title="Printing System">
                        <div style={{ width: '100%', marginLeft: 10 }}>
                            <div>
                                {coanting1st.length != 0 ? <Addcolorprinting tecbid={coanting1st.tecbid} typetecb={'1st'} /> : <div>loading</div>}
                            </div>
                            <div>
                                {coanting2nd.length != 0 ? <Addcolorprinting tecbid={coanting2nd.tecbid} typetecb={'2nd'} /> : <div>loading</div>}
                            </div>
                            <div>
                                {coanting3rd.length != 0 ? <Addcolorprinting tecbid={coanting3rd.tecbid} typetecb={'3rd'} /> : <div>loading</div>}
                            </div>
                            <div>
                                {coanting4th.length != 0 ? <Addcolorprinting tecbid={coanting4th.tecbid} typetecb={'4th'} /> : <div>loading</div>}
                            </div>
                            <div>
                                {coanting5th.length != 0 ? <Addcolorprinting tecbid={coanting5th.tecbid} typetecb={'5th'} /> : <div>loading</div>}
                            </div>
                            <div>
                                {coanting6th.length != 0 ? <Addcolorprinting tecbid={coanting6th.tecbid} typetecb={'6th'} /> : <div>loading</div>}
                            </div>
                            <div>
                                {coanting6th.length != 0 ? <Addcolorprinting tecbid={coanting7th.tecbid} typetecb={'7th'} /> : <div>loading</div>}
                            </div>

                        </div>
                    </Tab> */}
                </Tabs>
            </div>

        </div>}
        </div>
    )
}

export default AllDetailpartb