import React, { useState, useEffect } from 'react'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';
import FetchApi from '../../../../customhooks/functionFetchApi';

const PopUpaddDetailcolors = (props) => {
  const FetchApis = new FetchApi();
  // {props.colorId}
  //{props.journalId}
  const [smShow, setSmShow] = useState(false);
  const [lgShow, setLgShow] = useState(false);
  const [colorformulate, setColorFormulate] = useState({ formulate: "", kg: "" })

  const [allformulate, setAllformulate] = useState({
    inkname: "",
    formulate: "",
    kg1: 0,
    kg2: 0,
    formulate_params: 0,
    Kg_params: 0,
    colorId: props.colorId,
    journalId: props.journalId
  });

  const [colorAll, setColorAll] = useState([]);

  useEffect(() => {
    getdataColorDetail()
  }, [])


  function addColor() {
    if (colorformulate.formulate == 0 || colorformulate.kg == 0) {

      if (allformulate.inkname !== "") {
        FetchApis.FethcPost(`/productcolordetail/createcolor`, allformulate).then((res) => {
          if (res.status == 200) {
            getdataColorDetail();
          }
        })
      }

    } else {

      let sumformulate = (allformulate.formulate / colorformulate.formulate) * 100;

      let sumkg = (sumformulate * colorformulate.kg) / 100

      let dataobject = {
        inkname: allformulate.inkname,
        formulate: sumformulate.toFixed(2),
        kg1: sumkg.toFixed(3),
        kg2: "",
        formulate_params: colorformulate.formulate,
        Kg_params: colorformulate.kg,
        colorId: props.colorId,
        journalId: props.journalId
      }

      if (allformulate.inkname !== "") {
        FetchApis.FethcPost(`/productcolordetail/createcolor`, dataobject).then((res) => {
          if (res.status == 200) {
            getdataColorDetail();
          }
        })
      }
    }
  }

  function getdataColorDetail() {
    FetchApis.FethcGet(`/productcolordetail/getproductByColorId/${props.colorId}`).then((res) => {
      if (res.status == 200) {
     
        setColorAll(res.data.data);
        let datacolor = res.data.data[0];

        if (res.data.data.length > 0) {
          setColorFormulate({
            ...colorformulate,
            formulate: datacolor.formulate_params,
            kg: datacolor.Kg_params
          })
        }


      }
    })
  }

  function delectformulate(ID) {

    FetchApis.FethcDelete(`/productcolordetail/deleteById/${ID}`).then((res) => {
      if (res) {
        getdataColorDetail()
    
      }
    })
  }

  function formulatesum() {

    let formulate = 0;
    for (let index = 0; index < colorAll.length; index++) {
      formulate += parseFloat(colorAll[index].formulate)

    }
    if (colorAll.length > 0) {
      if (formulate >= 99.99) {
        formulate = 100;
      }

  }
    return formulate.toFixed(2)
  }


  function sumkg() {
   
    let kg2 = 0;
    for (let index = 0; index < colorAll.length; index++) {
      kg2 += parseFloat(colorAll[index].kg1)

    }

    if (kg2 >= parseFloat(colorformulate.kg)) {
      kg2 = parseFloat(colorformulate.kg)

    }else{
     let data1=kg2-parseFloat(colorformulate.kg);
         
     if(data1.toFixed(3)==-0.001){
      kg2=parseFloat(colorformulate.kg);
     }
   
    }
    return kg2.toFixed(3)
  }

  function onClose() {
    props.reloadingdata();

    setLgShow(false)
  }


  return (
    <>
      <Button onClick={() => setLgShow(true)} size='sm'>เพิ่มข้อมูลสูตร coler</Button>

      <Modal
        size="lg"
        show={lgShow}
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            เพิ่มข้อมูลสูตร coler
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>

            <div className='row-page'>
              <div className='row-page'>
                <div>น้ำหนักรวม  :</div>
                <div><input type='number'
                  value={colorformulate.formulate}
                  onChange={(e) => setColorFormulate({ ...colorformulate, formulate: e.target.value })} /> </div>
              </div>

              <div className='row-page'>
                <div>จำนวน Kg :</div>
                <div><input type='number'
                  value={colorformulate.kg}
                  onChange={(e) => setColorFormulate({ ...colorformulate, kg: e.target.value })} /></div>
              </div>
            </div>
            <hr></hr>
            <div className='row-page'>
              <div style={{ width: '30%' }}>
                <div><label>Ink name </label></div>
                <input type='text' onChange={(e) => setAllformulate({ ...allformulate, inkname: e.target.value })} />
              </div>
              <div style={{ width: '30%' }}>
                <div>น้ำหนัก  </div>
                <div><input type='number' style={{ width: 100 }} onChange={(e) => setAllformulate({ ...allformulate, formulate: e.target.value })} /> </div>
              </div>
              <div style={{ padding: 10 }}>
                <center>
                  <Button onClick={() => addColor()}>เพิ่มข้อมูล</Button>
                </center>
              </div>

            </div>
            <div>
              <div><label>แสดงข้อมูล color</label></div>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Ink Name</th>
                    <th>Formulat</th>
                    <th>Kg /1,000</th>
                    <th>delete</th>
                  </tr>
                </thead>
                {colorAll.map((item, i) => (
                  <tbody>
                    <tr>
                      <td>{i + 1}</td>
                      <td>{item.inkname}</td>
                      <td>{item.formulate}</td>
                      <td>{item.kg1}</td>
                      <td>
                        <center><Button size='sm' variant="warning"
                          onClick={() => delectformulate(item.colordetailId)}>ลบ</Button></center>
                      </td>
                    </tr>
                  </tbody>

                ))}
                <tr>
                  <td></td>
                  <td>Total</td>
                  <td>{formulatesum()}</td>
                  <td>{sumkg()}</td>
                  <td>
                  </td>
                </tr>
              </Table>
              <center>
                <Button variant='success' onClick={() => onClose()}>บันทึกข้อมูล</Button>
              </center>
            </div>

          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default PopUpaddDetailcolors