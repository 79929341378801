import React, { useEffect, useState } from 'react'
import { TiDelete } from "react-icons/ti";
import FetchApi from '../../../customhooks/functionFetchApi';
function Tablecolordetail(props) {

    const FetchApis = new FetchApi();
    const [colorall, setColorall] = useState([])

    useEffect(() => {
        FetchApis.FethcGet(`/productcolordetail/getproductByColorId/${props.colorId}`).then((res) => {
            if (res.status == 200) {
                setColorall(res.data.data)
            }
        })
    }, [])


    function sumkgformulate() {
        let kg1 = 0;
        for (let index = 0; index < colorall.length; index++) {
            kg1 += parseFloat(colorall[index].formulate)

        }

        if (colorall.length > 0) {
            if (kg1 >= 99.99) {
                kg1 = 100;
            }

        }

        return kg1.toFixed(2)
    }

    function sumkg() {

        let kg2 = 0;
        for (let index = 0; index < colorall.length; index++) {
            kg2 += parseFloat(colorall[index].kg1)

        }

        if (kg2 >= parseFloat(colorall[0].Kg_params)) {
            kg2 = parseFloat(colorall[0].Kg_params)

        } else {
            let data1 = kg2 - parseFloat(colorall[0].Kg_params);

            if (data1.toFixed(3) == -0.001) {
                kg2 = parseFloat(colorall[0].Kg_params);
            }

        }
        return kg2.toFixed(3)
    }


    return (
        <div className='row-page'>
            <div style={{ width: '100%' }}>
                <div style={{ width: '100%', backgroundColor: '#afafaf' }}>
                    {/* <label style={{ fontSize: 16, marginLeft: 10 }}><b>{props.data.number} .  Color : </b></label>
                    <label style={{ fontSize: 16, marginLeft: 10 }}>{props.data.colorheader}</label> */}
                </div>
                <table style={{ width: '100%' }}>
                    <tr>
                        <th style={{ width: '20%' }}>Ink Name</th>
                        <th style={{ width: '20%' }}>Formulate (%)</th>
                        <th style={{ width: '20%' }}>{colorall.length > 0 && colorall[0].Kg_params} kg/1000</th>


                    </tr>

                    {colorall.map((item, i) => (
                        <tr>
                            <td>{item.inkname} </td>
                            <td>{item.formulate}</td>
                            <td>{item.kg1}</td>


                        </tr>
                    ))}
                    <tr>
                        <td><center><b>Total</b></center></td>
                        <td>
                            <center>{colorall.length > 1 && sumkgformulate()}</center>
                        </td>
                        <td>
                            <center>{colorall.length > 1 && sumkg()}</center>

                        </td>

                    </tr>
                </table>
            </div>
            {/* <div style={{ width: '25%', backgroundColor: '#eeeded' }}>
                <div style={{ backgroundColor: '#afafaf' }}>
                    <label><b>Remark</b></label>
                </div>
                <label>{props.data.remark}</label>
            </div> */}

            <hr></hr>
        </div>
    )
}

export default Tablecolordetail