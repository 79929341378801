import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import FetchApi, { host } from '../../../customhooks/functionFetchApi';
import { Button } from 'react-bootstrap';
import Addcolorprinting from './addcolorprinting';
import PrintingApproveState from './printingApproveState';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Showdetailjournal from '../../../form01/components/showdetailjournal';
import ViewImages from '../../../form01/components/viewImages';
import ViewProductionsyspartb from '../../components/veiwproductionsyspartb';
import { CiImageOn } from "react-icons/ci";
import Table from 'react-bootstrap/Table';
import Swal from 'sweetalert2';
// import uuid from 'react-uuid';
import Tablecolor from './tablecolor';
import Tablecolordetail from './tablecolordetail';
import PopUpaddDetailcolors from './popupcomponent/popUpaddDetailcolors';

const objectPartB = {
    tecbid: "", sequence: "",
    bodyside: "", bodycolor: "", bodyfm: "", bodydfw_min: "", bodydfw_max: "",
    bodywfw: "", bodytemp: "", bodyspeed: "", bodyrubber: "",
    topside: "", topcolor: "", topfm: "", topdfw_min: "", topdfw_max: "", topwfw: "", toptemp: "", topspeed: "", toprubber: "",
    bottomside: "", bottomcolor: "", bottomfm: "", bottomdfw_min: "", bottomdfw_max: "", bottomwfw: "", bottomtemp: "",
    bottomspeed: "", bottomrubber: "", otherdetail: "", journalId: "", tdscode: ""
}


const colorheader = {
    number: 0,
    colorheader: "",
    detailcolor: [],
    remark: ""
}


const PrintingDetailpartb = ({ codeId, journalId, stateflow }) => {

    const FetchApis = new FetchApi();
    const [key, setKey] = useState('partB');
    const [jouranldata, setJournaldata] = useState([]);

    const [coanting1st, setCoanting1st] = useState(objectPartB);
    const [coanting2nd, setCoanting2nd] = useState(objectPartB);
    const [coanting3rd, setCoanting3rd] = useState(objectPartB);
    const [coanting4th, setCoanting4th] = useState(objectPartB);
    const [coanting5th, setCoanting5th] = useState(objectPartB);
    const [coanting6th, setCoanting6th] = useState(objectPartB);
    const [coanting7th, setCoanting7th] = useState(objectPartB);

    // detail ink
    const [colorlist1, setColorlist1] = useState(colorheader);
    const [colorlist2, setColorlist2] = useState(colorheader);
    const [colorlist3, setColorlist3] = useState(colorheader);
    const [colorlist4, setColorlist4] = useState(colorheader);
    //header ink
    const [colorall, setColorall] = useState({ colorheader: "", remark: "", typecolor: "", number: '' });
    const [jourColorHeader, setJourColorHeader] = useState([])

    const [fileLoad, setFileLoad] = useState("")
    const [checkdata, setCheckdata] = useState(false);

    const [loadingdetail, setLoadingdetail] = useState(false);

    useEffect(() => {

        FetchApis.FethcGet(`/technical/techniaclByJournalId/${journalId}`).then((res) => {

            if (res.status == 200) {
                if (res.data.data.length != 0) {
                    setCheckdata(true)
                    Coatinglistbytype(res.data.data)
                    datalistjournal()
                    getproductcolor(journalId)
                    getColorHearder(journalId)

                }
            }
        })

    }, [colorall])


    function datalistjournal() {
        FetchApis.FethcGet(`/journal/journalbyId/${journalId}`).then((res) => {
            if (res) {
                setJournaldata(res.data.data[0]);
            }
        })
    }

    function getproductcolor(jourId) {

        FetchApis.FethcGet(`/productcolor/getproductByjournal/${jourId}`).then((res) => {
            if (res) {
                let colorallss = res.data.data;

                for (let index = 0; index < colorallss.length; index++) {
                    let resultcolor = colorallss[index];

                    if (resultcolor.number == 1) {
                        let tdcolor = colorallss[index];

                        getproductcolordetail(resultcolor.colorId, resultcolor.number, tdcolor)

                    }

                    if (resultcolor.number == 2) {
                        let tdcolor = colorallss[index];

                        getproductcolordetail(resultcolor.colorId, resultcolor.number, tdcolor)
                    }

                    if (resultcolor.number == 3) {
                        let tdcolor = colorallss[index];

                        getproductcolordetail(resultcolor.colorId, resultcolor.number, tdcolor)
                    }

                    if (resultcolor.number == 4) {
                        let tdcolor = colorallss[index];

                        getproductcolordetail(resultcolor.colorId, resultcolor.number, tdcolor)
                    }

                }

            }
        })
    }


    function getproductcolordetail(colorid, typenumber, resultcolor) {

        if (typenumber == 1) {
            FetchApis.FethcGet(`/productcolordetail/getproductByColorId/${colorid}`).then((res) => {
                const { colorheader, detailcolor, remark } = colorlist1;

                if (res.data.data.length > 0) {
                    setColorlist1({
                        ...colorlist1,
                        colorheader: resultcolor.colorname,
                        detailcolor: res.data.data,
                        remark: resultcolor.remark,
                        number: 1
                    })
                } else {
                    setColorlist1({
                        ...colorlist1,
                        colorheader: resultcolor.colorname,
                        remark: resultcolor.remark,
                        number: 1
                    })
                }

            })
        }

        if (typenumber == 2) {
            FetchApis.FethcGet(`/productcolordetail/getproductByColorId/${colorid}`).then((res) => {
                console.log(res.data.data)
                const { colorheader, detailcolor, remark } = colorlist2;

                if (res.data.data.length > 0) {
                    setColorlist2({
                        ...colorlist2,
                        colorheader: resultcolor.colorname,
                        detailcolor: res.data.data,
                        remark: resultcolor.remark,
                        number: 2
                    })
                } else {
                    setColorlist2({
                        ...colorlist2,
                        colorheader: resultcolor.colorname,
                        remark: resultcolor.remark,
                        number: 2
                    })
                }
            })
        }

        if (typenumber == 3) {
            FetchApis.FethcGet(`/productcolordetail/getproductByColorId/${colorid}`).then((res) => {

                const { colorheader, detailcolor, remark } = colorlist3;

                if (res.data.data.length > 0) {
                    setColorlist3({
                        ...colorlist3,
                        colorheader: resultcolor.colorname,
                        detailcolor: res.data.data,
                        remark: resultcolor.remark,
                        number: 3
                    })
                } else {
                    setColorlist3({
                        ...colorlist3,
                        colorheader: resultcolor.colorname,
                        remark: resultcolor.remark,
                        number: 3
                    })
                }
            })
        }

        if (typenumber == 4) {

            FetchApis.FethcGet(`/productcolordetail/getproductByColorId/${colorid}`).then((res) => {

                const { colorheader, detailcolor, remark } = colorlist4;
                if (res.data.data.length > 0) {
                    setColorlist4({
                        ...colorlist4,
                        colorheader: resultcolor.colorname,
                        detailcolor: res.data.data,
                        remark: resultcolor.remark,
                        number: 4
                    })
                } else {
                    setColorlist4({
                        ...colorlist4,
                        colorheader: resultcolor.colorname,
                        remark: resultcolor.remark,
                        number: 4
                    })
                }
            })
        }

    }

    function Coatinglistbytype(datalist) {
        const coating1st = datalist.find((item) => item.sequence == '1st');
        setCoanting1st(coating1st);

        const coating2nd = datalist.find((item) => item.sequence == '2nd');
        setCoanting2nd(coating2nd);

        const coating3rd = datalist.find((item) => item.sequence == '3rd');
        setCoanting3rd(coating3rd);

        const coating4th = datalist.find((item) => item.sequence == '4th');
        setCoanting4th(coating4th);

        const coating5th = datalist.find((item) => item.sequence == '5th');
        setCoanting5th(coating5th);


        const coating6th = datalist.find((item) => item.sequence == '6th');
        setCoanting6th(coating6th);

        const coating7th = datalist.find((item) => item.sequence == '7th');
        setCoanting7th(coating7th);
    }

    const handleFile = event => {
        var input = document.getElementById('loadfile');
        const file = input.files[0]

        if (file != undefined) {
            setFileLoad(input.files[0])
        } else {
            setFileLoad("")
        }

    };


    const Uploadfilecolor = () => {

        if (fileLoad.size / 1024 > 500) {

            Swal.fire({
                title: "แจ้งเตือน!",
                text: "file size must not be greater than to 500k",
                icon: "warning"
            });

        } else {

            Swal.fire({
                title: "บันทึกเอกสาร",
                text: "คุณแน่ใจที่บันทึกเอกสาร ใช่หรือไม่ ! ",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "ใช่",
                cancelButtonText: 'ยกเลิก'
            }).then((result) => {

                if (result.isConfirmed) {

                    if (fileLoad !== "") {
                        if (fileLoad.size / 1024 > 500) {

                            Swal.fire({
                                title: "แจ้งเตือน!",
                                text: "file size must not be greater than to 500k",
                                icon: "warning"
                            });
                        } else {
                            FetchApis.FetchUploadfile('/images/uploadfile', fileLoad).then(res => {

                                if (res.success == true) {
                                    var dataresualt = res.filename

                                    var datafile = { filename: dataresualt }

                                    FetchApis.FethcUpdate(`/journal/updatefilecolor/${journalId}`, datafile).then((result) => {

                                        if (result.status == 200) {
                                            Swal.fire({
                                                title: "บันทึกเอกสาร!",
                                                text: "คุณได้ทำบันทึกเอกสารเรียบแล้ว",
                                                icon: "success"
                                            });
                                            // loadingdata();
                                            window.location.reload()

                                        }
                                        else {

                                            alert(result.message)
                                        }

                                    })

                                }

                            })
                        }
                    }
                    else {
                        Swal.fire({
                            title: "แจ้งเตือน!",
                            text: "คุณยังไม่ได้เลือก file ข้อมูล",
                            icon: "warning"
                        });
                    }
                }
            })
        }
    }

    // check color type function 
    function onchangTypecolor(e) {
        const { value, name } = e.target
        if (value == 'color1') {
            let colorhears = jourColorHeader.find((item) => item.number == 1)
            if (colorhears != undefined) {
                setColorall({
                    ...colorall,
                    typecolor: value,
                    colorheader: colorhears.colorname,
                    remark: colorhears.remark
                })
            } else {
                setColorall({
                    ...colorall,
                    typecolor: value
                })
            }


        } else if (value == 'color2') {
            let colorhears = jourColorHeader.find((item) => item.number == 2)
            if (colorhears != undefined) {
                setColorall({
                    ...colorall,
                    typecolor: value,
                    colorheader: colorhears.colorname,
                    remark: colorhears.remark
                })
            }
            else {
                setColorall({
                    ...colorall,
                    typecolor: value
                })
            }
        } else if (value == 'color3') {
            let colorhears = jourColorHeader.find((item) => item.number == 3)
            if (colorhears != undefined) {
                setColorall({
                    ...colorall,
                    typecolor: value,
                    colorheader: colorhears.colorname,
                    remark: colorhears.remark
                })
            }
            else {
                setColorall({
                    ...colorall,
                    typecolor: value
                })
            }
        } else if (value == 'color4') {
            let colorhears = jourColorHeader.find((item) => item.number == 4)
            if (colorhears != undefined) {
                setColorall({
                    ...colorall,
                    typecolor: value,
                    colorheader: colorhears.colorname,
                    remark: colorhears.remark
                })
            }
            else {
                setColorall({
                    ...colorall,
                    typecolor: value
                })
            }
        } else if (value == 'color5') {
            let colorhears = jourColorHeader.find((item) => item.number == 5)
            if (colorhears != undefined) {
                setColorall({
                    ...colorall,
                    typecolor: value,
                    colorheader: colorhears.colorname,
                    remark: colorhears.remark
                })
            }
            else {
                setColorall({
                    ...colorall,
                    typecolor: value
                })
            }

        } else if (value == 'color6') {
            let colorhears = jourColorHeader.find((item) => item.number == 6)
            if (colorhears != undefined) {
                setColorall({
                    ...colorall,
                    typecolor: value,
                    colorheader: colorhears.colorname,
                    remark: colorhears.remark
                })
            }
            else {
                setColorall({
                    ...colorall,
                    typecolor: value
                })
            }
        } else if (value == 'color7') {
            let colorhears = jourColorHeader.find((item) => item.number == 7)
            if (colorhears != undefined) {
                setColorall({
                    ...colorall,
                    typecolor: value,
                    colorheader: colorhears.colorname,
                    remark: colorhears.remark
                })
            }
            else {
                setColorall({
                    ...colorall,
                    typecolor: value
                })
            }
        }

    }

    // add hearder color
    function AddColorList() {
        if (colorall.typecolor == 'color1') {
            insertcolor(colorall, 1)
        }
        else if (colorall.typecolor == 'color2') {
            insertcolor(colorall, 2)
        }
        else if (colorall.typecolor == 'color3') {
            insertcolor(colorall, 3)
        }
        else if (colorall.typecolor == 'color4') {
            insertcolor(colorall, 4)
        }
        else if (colorall.typecolor == 'color5') {
            insertcolor(colorall, 5)
        }
        else if (colorall.typecolor == 'color6') {
            insertcolor(colorall, 6)
        }
        else if (colorall.typecolor == 'color7') {
            insertcolor(colorall, 7)
        }
        else {
            alert('คุณยังไม่ได้เลือก color number')
        }
    }

    // edit header color
    function EditColorHeader() {
        if (colorall.typecolor == 'color1') {

            let colornumber = jourColorHeader.find((item) => item.number == 1)
            if (colornumber != undefined) {
                editcolorhearder(colornumber.colorId, colorall)
            }
        }
        else if (colorall.typecolor == 'color2') {
            let colornumber = jourColorHeader.find((item) => item.number == 2)
            if (colornumber != undefined) {
                editcolorhearder(colornumber.colorId, colorall)
            }

        }
        else if (colorall.typecolor == 'color3') {
            let colornumber = jourColorHeader.find((item) => item.number == 3)
            if (colornumber != undefined) {
                editcolorhearder(colornumber.colorId, colorall)
            }
        }
        else if (colorall.typecolor == 'color4') {
            let colornumber = jourColorHeader.find((item) => item.number == 4)
            if (colornumber != undefined) {
                editcolorhearder(colornumber.colorId, colorall)
            }
        }
        else if (colorall.typecolor == 'color5') {
            let colornumber = jourColorHeader.find((item) => item.number == 5)
            if (colornumber != undefined) {
                editcolorhearder(colornumber.colorId, colorall)
            }

        }
        else if (colorall.typecolor == 'color6') {
            let colornumber = jourColorHeader.find((item) => item.number == 6)
            if (colornumber != undefined) {
                editcolorhearder(colornumber.colorId, colorall)
            }
        }
        else if (colorall.typecolor == 'color7') {
            let colornumber = jourColorHeader.find((item) => item.number == 7)
            if (colornumber != undefined) {
                editcolorhearder(colornumber.colorId, colorall)
            }
        }
        else {
            alert('คุณยังไม่ได้เลือก color number')
        }
    }

    // remove all type
    function removeAllbyType() {
        if (colorall.typecolor == 'color1') {

            setColorlist1({
                ...colorlist1,
                colorheader: "",
                detailcolor: [],
                remark: ""
            })
            deletebycolortype(1, journalId)
        }
        else if (colorall.typecolor == 'color2') {

            setColorlist2({
                ...colorlist2,
                colorheader: "",
                detailcolor: [],
                remark: ""
            })
            deletebycolortype(2, journalId)
        }
        else if (colorall.typecolor == 'color3') {

            setColorlist3({
                ...colorlist3,
                colorheader: "",
                detailcolor: [],
                remark: ""
            })
            deletebycolortype(3, journalId)
        }
        else if (colorall.typecolor == 'color4') {

            setColorlist4({
                ...colorlist4,
                colorheader: "",
                detailcolor: [],
                remark: ""
            })
            deletebycolortype(4, journalId)

        }
        else {
            alert('คุณยังไม่ได้เลือก color number')
        }
    }

    // insert to table productcolor
    function insertcolor(datalist, numbers) {

        const object1 = {
            number: numbers,
            colorname: datalist.colorheader,
            remark: datalist.remark,
            journalId: journalId
        }
        FetchApis.FethcPost(`/productcolor/createcolor`, object1).then((res) => {
            if (res.status == 200) {
                getColorHearder(journalId)

            }
        })
    }

    function editcolorhearder(jourid, jourdata) {

        const object1 = {
            colorname: jourdata.colorheader,
            remark: jourdata.remark
        }

        FetchApis.FethcUpdate(`/productcolor/updateByid/${jourid}`, object1).then((res) => {
            if (res) {
                getColorHearder(journalId);
            } else {
                console.log(res)
            }

        })
    }

    // get  data header color
    function getColorHearder(idjour) {
        FetchApis.FethcGet(`/productcolor/getproductByjournal/${idjour}`).then((res) => {

            setJourColorHeader(res.data.data)
        })
    }

    // delete all type number
    function deletebycolortype(number, journal) {
        let datadelete = {
            number: number,
            journalId: journal
        }
        FetchApis.FethcPost(`/productcolor/deleteByjournal`, datadelete).then((res) => {
            if (res.status == 200) {
                console.log('delete ok ');
                getColorHearder(journal);
            }
        })
    }

    function reloadingdata() {
        setLoadingdetail(true)
        setTimeout(() => {
            setLoadingdetail(false)
        }, 700);
    }


    return (
        <div>{checkdata == false ? <div>
            <div className='row-page-box'>
                ยังไม่มีข้อมูล Part B
            </div>

        </div> : <div>
            <div className='end-flex-row'>
                <PrintingApproveState idjournal={journalId} stateflow={stateflow} />
            </div>
            <div className='row-between-page' style={{ backgroundColor: '#CDCBC7', padding: 10, borderRadius: 5, width: '100%' }}>
                <div style={{ width: '70%' }}><b>TECHNICAL DATA SHEET PART B </b> </div>
                <div style={{ width: '20%' }}><b>Ref .MK .NO {jouranldata.tdscode}</b></div>
                <div style={{ width: '10%' }}><b>Date : {jouranldata.createdate}</b></div>
            </div>


            <div >
                <Tabs
                    id="controlled-tab-example"
                    activeKey={key}
                    onSelect={(k) => setKey(k)}
                    className="mb-3"
                >
                    <Tab eventKey="partA" title="Technical data sheet part A">
                        <Showdetailjournal id={journalId} />
                    </Tab>
                    <Tab eventKey="partB" title="Technical data sheet part B">
                        <div style={{ width: '100%' }}>


                            <table style={{ width: '100%' }}>
                                <tr>
                                    <th style={{ width: '5%' }}><center>sequence</center></th>
                                    <th style={{ width: '20%' }}><center>Coating system</center></th>
                                    <th style={{ width: '15%' }}><center>Body</center></th>
                                    <th style={{ width: '15%' }}><center>Top end</center></th>
                                    <th style={{ width: '15%' }}><center>Bottom end</center></th>
                                    <th style={{ width: '20%' }}><center>Other</center></th>
                                </tr>
                                <tr>
                                    <td ><center><b>1 st </b></center></td>
                                    <td >Side</td>
                                    <td >{coanting1st.bodyside}</td>
                                    <td >{coanting1st.topside}</td>
                                    <td >{coanting1st.bottomside}</td>
                                    <div style={{ padding: 5 }}>{coanting1st.otherdetail}</div>
                                </tr>
                                <tr>
                                    <td ></td>
                                    <td >Color shade</td>
                                    <td >{coanting1st.bodycolor}</td>
                                    <td >{coanting1st.topcolor}</td>
                                    <td >{coanting1st.bottomcolor}</td>

                                </tr>
                                <tr>
                                    <td ></td>
                                    <td >FM.code</td>
                                    <td >{coanting1st.bodyfm}</td>
                                    <td >{coanting1st.topfm}</td>
                                    <td >{coanting1st.bottomfm}</td>

                                </tr>
                                <tr>
                                    <td ></td>
                                    <td >DFW ( gsm ) / wfw (Kg / 1000 Sht)</td>
                                    <td >{coanting1st.bodydfw_min != "" && <div>{coanting1st.bodydfw_min} - {coanting1st.bodydfw_max} / {coanting1st.bodywfw}</div>}</td>
                                    <td >{coanting1st.topdfw_min != "" && <div>{coanting1st.topdfw_min} - {coanting1st.topdfw_max} / {coanting1st.topwfw}</div>}</td>
                                    <td >{coanting1st.bottomdfw_min != "" && <div>{coanting1st.bottomdfw_min} - {coanting1st.bottomdfw_max} / {coanting1st.bottomwfw}</div>}</td>

                                </tr>
                                <tr>
                                    <td ></td>
                                    <td >Temp ( C )</td>
                                    <td >{coanting1st.bodytemp}</td>
                                    <td >{coanting1st.toptemp}</td>
                                    <td >{coanting1st.bottomtemp}</td>

                                </tr>
                                <tr>
                                    <td ></td>
                                    <td >Speed ( sph) / No .Rubber</td>
                                    <td ><div>{coanting1st.bodyspeed != "" ? <div>{coanting1st.bodyspeed} / {coanting1st.bodyrubber}</div> : <div></div>}</div></td>
                                    <td ><div>{coanting1st.topspeed != "" ? <div>{coanting1st.topspeed} / {coanting1st.toprubber}</div> : <div></div>}</div></td>
                                    <td ><div>{coanting1st.bottomspeed != "" ? <div> {coanting1st.bottomspeed} / {coanting1st.bottomrubber}</div> : <div></div>} </div></td>

                                </tr>
                            </table>


                            <table style={{ width: '100%' }}>
                                <tr>
                                    <th style={{ width: '5%' }}><center>sequence</center></th>
                                    <th style={{ width: '20%' }}></th>
                                    <th style={{ width: '15%' }}></th>
                                    <th style={{ width: '15%' }}></th>
                                    <th style={{ width: '15%' }}></th>
                                    <th style={{ width: '20%' }}></th>
                                </tr>
                                <tr>
                                    <td ><center><b>2 nd  </b></center></td>
                                    <td >Side</td>
                                    <td >{coanting2nd.bodyside}</td>
                                    <td >{coanting2nd.topside}</td>
                                    <td >{coanting2nd.bottomside}</td>
                                    <div style={{ padding: 5 }}>{coanting2nd.otherdetail}</div>
                                </tr>
                                <tr>
                                    <td ><center><b></b></center></td>
                                    <td >Color shade</td>
                                    <td >{coanting2nd.bodycolor}</td>
                                    <td >{coanting2nd.topcolor}</td>
                                    <td >{coanting2nd.bottomcolor}</td>
                                </tr>
                                <tr>
                                    <td ></td>
                                    <td >FM.code</td>
                                    <td >{coanting2nd.bodyfm}</td>
                                    <td >{coanting2nd.topfm}</td>
                                    <td >{coanting2nd.bottomfm}</td>
                                </tr>
                                <tr>
                                    <td ></td>
                                    <td >DFW ( gsm ) / wfw (Kg / 1000 Sht)</td>
                                    <td >{coanting2nd.bodydfw_min != "" && <div>{coanting2nd.bodydfw_min} - {coanting2nd.bodydfw_max} / {coanting2nd.bodywfw}</div>}</td>
                                    <td >{coanting2nd.topdfw_min != "" && <div>{coanting2nd.topdfw_min} - {coanting2nd.topdfw_max} / {coanting2nd.topwfw}</div>}</td>
                                    <td >{coanting2nd.bottomdfw_min != "" && <div>{coanting2nd.bottomdfw_min} - {coanting2nd.bottomdfw_max} / {coanting2nd.bottomwfw}</div>}</td>

                                </tr>
                                <tr>
                                    <td ></td>
                                    <td >Temp ( C ) </td>
                                    <td >{coanting2nd.bodytemp}</td>
                                    <td >{coanting2nd.toptemp}</td>
                                    <td >{coanting2nd.bottomtemp}</td>
                                </tr>
                                <tr>
                                    <td ></td>
                                    <td >Speed ( sph) / No .Rubber</td>
                                    <td >{coanting2nd.bodyspeed != "" ? <div>{coanting2nd.bodyspeed} / {coanting2nd.bodyrubber}</div> : <div></div>}</td>
                                    <td >{coanting2nd.topspeed != "" ? <div>{coanting2nd.topspeed} / {coanting2nd.toprubber}</div> : <div></div>}</td>
                                    <td >{coanting2nd.bottomspeed != "" ? <div>{coanting2nd.bottomspeed} / {coanting2nd.bottomrubber}</div> : <div></div>}</td>
                                </tr>
                            </table>

                            <table style={{ width: '100%' }}>
                                <tr>
                                    <th style={{ width: '5%' }}><center>sequence</center></th>
                                    <th style={{ width: '20%' }}></th>
                                    <th style={{ width: '15%' }}></th>
                                    <th style={{ width: '15%' }}></th>
                                    <th style={{ width: '15%' }}></th>
                                    <th style={{ width: '20%' }}></th>
                                </tr>
                                <tr>
                                    <td ><center><b>3 rd  </b></center></td>
                                    <td >Side</td>
                                    <td >{coanting3rd.bodyside}</td>
                                    <td >{coanting3rd.topside}</td>
                                    <td >{coanting3rd.bottomside}</td>
                                    <div style={{ padding: 5 }}>{coanting3rd.otherdetail}</div>
                                </tr>
                                <tr>
                                    <td ></td>
                                    <td >Color shade</td>
                                    <td >{coanting3rd.bodycolor}</td>
                                    <td >{coanting3rd.topcolor}</td>
                                    <td >{coanting3rd.bottomcolor}</td>
                                </tr>
                                <tr>
                                    <td ></td>
                                    <td >FM.code</td>
                                    <td >{coanting3rd.bodyfm}</td>
                                    <td >{coanting3rd.topfm}</td>
                                    <td >{coanting3rd.bottomfm}</td>
                                </tr>
                                <tr>
                                    <td ></td>
                                    <td >DFW ( gsm ) / wfw (Kg / 1000 Sht)</td>
                                    <td >{coanting3rd.bodydfw_min != "" && <div>{coanting3rd.bodydfw_min} - {coanting3rd.bodydfw_max} / {coanting3rd.bodywfw}</div>}</td>
                                    <td >{coanting3rd.topdfw_min != "" && <div>{coanting3rd.topdfw_min} - {coanting3rd.topdfw_max} / {coanting3rd.topwfw}</div>}</td>
                                    <td >{coanting3rd.bottomdfw_min != "" && <div>{coanting3rd.bottomdfw_min} - {coanting3rd.bottomdfw_max} / {coanting3rd.bottomwfw}</div>}</td>
                                </tr>
                                <tr>
                                    <td ></td>
                                    <td >Temp ( C ) </td>
                                    <td >{coanting3rd.bodytemp}</td>
                                    <td >{coanting3rd.toptemp}</td>
                                    <td >{coanting3rd.bottomtemp}</td>
                                </tr>
                                <tr>
                                    <td ></td>
                                    <td >Speed ( sph) / No .Rubber</td>
                                    <td >{coanting3rd.bodyspeed != "" ? <div>{coanting3rd.bodyspeed} / {coanting3rd.bodyrubber}</div> : <div></div>}</td>
                                    <td >{coanting3rd.topspeed != "" ? <div>{coanting3rd.topspeed} / {coanting3rd.toprubber}</div> : <div></div>}</td>
                                    <td >{coanting3rd.bottomspeed != "" ? <div>{coanting3rd.bottomspeed} / {coanting3rd.bottomrubber}</div> : <div></div>}</td>
                                </tr>
                            </table>


                            <table style={{ width: '100%' }}>
                                <tr>
                                    <th style={{ width: '5%' }}><center>sequence</center></th>
                                    <th style={{ width: '20%' }}></th>
                                    <th style={{ width: '15%' }}></th>
                                    <th style={{ width: '15%' }}></th>
                                    <th style={{ width: '15%' }}></th>
                                    <th style={{ width: '20%' }}></th>
                                </tr>
                                <tr>
                                    <td ><center><b>4 th  </b></center></td>
                                    <td >Side</td>
                                    <td >{coanting4th.bodyside}</td>
                                    <td >{coanting4th.topside}</td>
                                    <td >{coanting4th.bottomside}</td>
                                    <div style={{ padding: 5 }}>{coanting4th.otherdetail}</div>
                                </tr>
                                <tr>
                                    <td ></td>
                                    <td >Color shade</td>
                                    <td >{coanting4th.bodycolor}</td>
                                    <td >{coanting4th.topcolor}</td>
                                    <td >{coanting4th.bottomcolor}</td>
                                </tr>
                                <tr>
                                    <td ></td>
                                    <td >FM.code</td>
                                    <td >{coanting4th.bodyfm}</td>
                                    <td >{coanting4th.topfm}</td>
                                    <td >{coanting4th.bottomfm}</td>
                                </tr>
                                <tr>
                                    <td ></td>
                                    <td >DFW ( gsm ) / wfw (Kg / 1000 Sht)</td>
                                    <td >{coanting4th.bodydfw_min != "" && <div>{coanting4th.bodydfw_min} - {coanting4th.bodydfw_max} / {coanting4th.bodywfw}</div>}</td>
                                    <td >{coanting4th.topdfw_min != "" && <div>{coanting4th.topdfw_min} - {coanting4th.topdfw_max} / {coanting4th.topwfw}</div>}</td>
                                    <td >{coanting4th.bottomdfw_min != "" && <div>{coanting4th.bottomdfw_min} - {coanting4th.bottomdfw_max} / {coanting4th.bottomwfw}</div>}</td>
                                </tr>
                                <tr>
                                    <td ></td>
                                    <td >Temp ( C )</td>
                                    <td >{coanting4th.bodytemp}</td>
                                    <td >{coanting4th.toptemp}</td>
                                    <td >{coanting4th.bottomtemp}</td>
                                </tr>
                                <tr>
                                    <td ></td>
                                    <td >Speed ( sph) / No .Rubber</td>
                                    <td >{coanting4th.bodyspeed != "" ? <div>{coanting4th.bodyspeed} / {coanting4th.bodyrubber}</div> : <div></div>}</td>
                                    <td >{coanting4th.topspeed != "" ? <div>{coanting4th.topspeed} / {coanting4th.toprubber}</div> : <div></div>}</td>
                                    <td >{coanting4th.bottomspeed != "" ? <div>{coanting4th.bottomspeed} /{coanting4th.bottomrubber}</div> : <div></div>}</td>
                                </tr>
                            </table>


                            <table style={{ width: '100%' }}>
                                <tr>
                                    <th style={{ width: '5%' }}><center>sequence</center></th>
                                    <th style={{ width: '20%' }}></th>
                                    <th style={{ width: '15%' }}></th>
                                    <th style={{ width: '15%' }}></th>
                                    <th style={{ width: '15%' }}></th>
                                    <th style={{ width: '20%' }}></th>
                                </tr>
                                <tr>
                                    <td ><center><b>5 th  </b></center></td>
                                    <td >Side</td>
                                    <td >{coanting5th.bodyside}</td>
                                    <td >{coanting5th.topside}</td>
                                    <td >{coanting5th.bottomside}</td>
                                    <div style={{ padding: 5 }}>{coanting5th.otherdetail}</div>
                                </tr>
                                <tr>
                                    <td ></td>
                                    <td >Color shade</td>
                                    <td >{coanting5th.bodycolor}</td>
                                    <td >{coanting5th.topcolor}</td>
                                    <td >{coanting5th.bottomcolor}</td>
                                </tr>
                                <tr>
                                    <td ></td>
                                    <td >FM.code</td>
                                    <td >{coanting5th.bodyfm}</td>
                                    <td >{coanting5th.topfm}</td>
                                    <td >{coanting5th.bottomfm}</td>
                                </tr>
                                <tr>
                                    <td ></td>
                                    <td >DFW ( gsm ) / wfw (Kg / 1000 Sht)</td>
                                    <td >{coanting5th.bodydfw_min != "" && <div>{coanting5th.bodydfw_min} - {coanting5th.bodydfw_max} / {coanting5th.bodywfw}</div>}</td>
                                    <td >{coanting5th.topdfw_min != "" && <div>{coanting5th.topdfw_min} - {coanting5th.topdfw_max} / {coanting5th.topwfw}</div>}</td>
                                    <td >{coanting5th.bottomdfw_min != "" && <div>{coanting5th.bottomdfw_min} - {coanting5th.bottomdfw_max} / {coanting5th.bottomwfw}</div>}</td>
                                </tr>
                                <tr>
                                    <td ></td>
                                    <td >Temp ( C ) </td>
                                    <td >{coanting5th.bodytemp}</td>
                                    <td >{coanting5th.toptemp}</td>
                                    <td >{coanting5th.bottomtemp}</td>
                                </tr>
                                <tr>
                                    <td ></td>
                                    <td >Speed ( sph) / No .Rubber</td>
                                    <td >{coanting5th.bodyspeed != "" ? <div>{coanting5th.bodyspeed} / {coanting5th.bodyrubber}</div> : <div></div>}</td>
                                    <td >{coanting5th.topspeed != "" ? <div>{coanting5th.topspeed} / {coanting5th.toprubber}</div> : <div></div>}</td>
                                    <td >{coanting5th.bottomspeed != "" ? <div>{coanting5th.bottomspeed} / {coanting5th.bottomrubber}</div> : <div></div>}</td>
                                </tr>
                            </table>


                            <table style={{ width: '100%' }}>
                                <tr>
                                    <th style={{ width: '5%' }}><center>sequence</center></th>
                                    <th style={{ width: '20%' }}></th>
                                    <th style={{ width: '15%' }}></th>
                                    <th style={{ width: '15%' }}></th>
                                    <th style={{ width: '15%' }}></th>
                                    <th style={{ width: '20%' }}></th>
                                </tr>
                                <tr>
                                    <td ><center><b>6 th  </b></center></td>
                                    <td >Side</td>
                                    <td >{coanting6th.bodyside}</td>
                                    <td >{coanting6th.topside}</td>
                                    <td >{coanting6th.bottomside}</td>
                                    <div style={{ padding: 5 }}>{coanting6th.otherdetail}</div>
                                </tr>
                                <tr>
                                    <td ></td>
                                    <td >Color shade</td>
                                    <td >{coanting6th.bodycolor}</td>
                                    <td >{coanting6th.topcolor}</td>
                                    <td >{coanting6th.bottomcolor}</td>
                                </tr>
                                <tr>
                                    <td ></td>
                                    <td >FM.code</td>
                                    <td >{coanting6th.bodyfm}</td>
                                    <td >{coanting6th.topfm}</td>
                                    <td >{coanting6th.bottomfm}</td>
                                </tr>
                                <tr>
                                    <td ></td>
                                    <td >DFW ( gsm ) / wfw (Kg / 1000 Sht)</td>
                                    <td >{coanting6th.bodydfw_min != "" && <div>{coanting6th.bodydfw_min} - {coanting6th.bodydfw_max} / {coanting6th.bodywfw}</div>}</td>
                                    <td >{coanting6th.topdfw_min != "" && <div>{coanting6th.topdfw_min} - {coanting6th.topdfw_max} / {coanting6th.topwfw}</div>}</td>
                                    <td >{coanting6th.bottomdfw_min != "" && <div>{coanting6th.bottomdfw_min} - {coanting6th.bottomdfw_max} / {coanting6th.bottomwfw}</div>}</td>
                                </tr>
                                <tr>
                                    <td ></td>
                                    <td >Temp ( C ) </td>
                                    <td >{coanting6th.bodytemp}</td>
                                    <td >{coanting6th.toptemp}</td>
                                    <td >{coanting6th.bottomtemp}</td>
                                </tr>
                                <tr>
                                    <td ></td>
                                    <td >Speed ( sph) / No .Rubber</td>
                                    <td >{coanting6th.bodyspeed != "" ? <div>{coanting6th.bodyspeed} / {coanting6th.bodyrubber}</div> : <div></div>}</td>
                                    <td >{coanting6th.topspeed != "" ? <div>{coanting6th.topspeed} / {coanting6th.toprubber}</div> : <div></div>}</td>
                                    <td >{coanting6th.bottomspeed != "" ? <div>{coanting6th.bottomspeed} / {coanting6th.bottomrubber}</div> : <div></div>}</td>
                                </tr>
                            </table>
                            <table style={{ width: '100%' }}>
                                <tr>
                                    <th style={{ width: '5%' }}><center>sequence</center></th>
                                    <th style={{ width: '20%' }}></th>
                                    <th style={{ width: '15%' }}></th>
                                    <th style={{ width: '15%' }}></th>
                                    <th style={{ width: '15%' }}></th>
                                    <th style={{ width: '20%' }}></th>
                                </tr>
                                <tr>
                                    <td ><center><b>7 th  </b></center></td>
                                    <td >Varnish top coat</td>
                                    <td >{coanting7th.bodyside}</td>
                                    <td >{coanting7th.topside}</td>
                                    <td >{coanting7th.bottomside}</td>
                                    <div >{coanting7th.otherdetail}</div>

                                </tr>
                                <tr>
                                    <td ></td>
                                    <td >Color shade</td>
                                    <td >{coanting7th.bodycolor}</td>
                                    <td >{coanting7th.topcolor}</td>
                                    <td >{coanting7th.bottomcolor}</td>
                                </tr>
                                <tr>
                                    <td ></td>
                                    <td >FM.code</td>
                                    <td >{coanting7th.bodyfm}</td>
                                    <td >{coanting7th.topfm}</td>
                                    <td >{coanting7th.bottomfm}</td>
                                </tr>
                                <tr>
                                    <td ></td>
                                    <td >DFW ( gsm ) / wfw (Kg / 1000 Sht)</td>
                                    <td >{coanting7th.bodydfw_min != "" && <div>{coanting7th.bodydfw_min} - {coanting7th.bodydfw_max} / {coanting7th.bodywfw}</div>}</td>
                                    <td >{coanting7th.topdfw_min != "" && <div>{coanting7th.topdfw_min} - {coanting7th.topdfw_max} / {coanting7th.topwfw}</div>}</td>
                                    <td >{coanting7th.bottomdfw_min != "" && <div>{coanting7th.bottomdfw_min} - {coanting7th.bottomdfw_max} / {coanting7th.bottomwfw}</div>}</td>
                                </tr>
                                <tr>
                                    <td ></td>
                                    <td >Temp ( C )</td>
                                    <td >{coanting7th.bodytemp}</td>
                                    <td >{coanting7th.toptemp}</td>
                                    <td >{coanting7th.bottomtemp}</td>
                                </tr>
                                <tr>
                                    <td ></td>
                                    <td >Speed ( sph) / No .Rubber</td>
                                    <td >{coanting7th.bodyspeed != "" ? <div>{coanting7th.bodyspeed} / {coanting7th.bodyrubber}</div> : <div></div>}</td>
                                    <td >{coanting7th.topspeed != "" ? <div>{coanting7th.topspeed} / {coanting7th.toprubber}</div> : <div></div>}</td>
                                    <td >{coanting7th.bottomspeed != "" ? <div>{coanting7th.bottomspeed} / {coanting7th.bottomrubber}</div> : <div></div>}</td>
                                </tr>
                            </table>
                            <ViewProductionsyspartb journalId={journalId} />
                        </div>
                    </Tab>
                    <Tab eventKey="confirmproduct" title="ข้อมูล confirm product">

                        <div>
                            <div style={{ marginTop: 10, padding: 10 }}>

                                {jouranldata.statusConfirm == '1' && <div>
                                    <input type='checkbox' value={'1'}
                                        defaultChecked={true} /> <b><label>confrim product approved</label></b>
                                </div>}

                            </div>
                            <div style={{ marginTop: 5, padding: 10 }}>
                                <div><b><label>อธิบายรายละเอียด</label></b></div>
                                <div><textarea value={jouranldata.commentscomfirm} style={{ width: '100%', height: 100 }}
                                    placeholder='ป้อนข้อมูลรายละเอียด'

                                /></div>
                            </div>

                            {jouranldata.fileproductConfirm != "" ? <div>
                                <Button variant="secondary">
                                    <a href={`${host}/images/files/${jouranldata.fileproductConfirm}`}> <label style={{ color: '#ffff' }}>Click load file ประกอบ ข้อมูล</label></a>
                                </Button>
                            </div> : <div></div>}

                        </div>

                    </Tab>
                    <Tab eventKey="printingsystem" title="Printing System">
                        <div style={{ width: '100%', marginLeft: 10 }}>
                            <div>
                                {coanting1st.length != 0 ? <Addcolorprinting tecbid={coanting1st.tecbid} typetecb={'1st'} /> : <div>loading</div>}
                            </div>
                            <div>
                                {coanting2nd.length != 0 ? <Addcolorprinting tecbid={coanting2nd.tecbid} typetecb={'2nd'} /> : <div>loading</div>}
                            </div>
                            <div>
                                {coanting3rd.length != 0 ? <Addcolorprinting tecbid={coanting3rd.tecbid} typetecb={'3rd'} /> : <div>loading</div>}
                            </div>
                            <div>
                                {coanting4th.length != 0 ? <Addcolorprinting tecbid={coanting4th.tecbid} typetecb={'4th'} /> : <div>loading</div>}
                            </div>
                            <div>
                                {coanting5th.length != 0 ? <Addcolorprinting tecbid={coanting5th.tecbid} typetecb={'5th'} /> : <div>loading</div>}
                            </div>
                            <div>
                                {coanting6th.length != 0 ? <Addcolorprinting tecbid={coanting6th.tecbid} typetecb={'6th'} /> : <div>loading</div>}
                            </div>
                            <div>
                                {coanting6th.length != 0 ? <Addcolorprinting tecbid={coanting7th.tecbid} typetecb={'7th'} /> : <div>loading</div>}
                            </div>

                        </div>
                    </Tab>
                    <Tab eventKey="AddDetailColor" title=" Uploadfile ส่วนประกอบ Color">
                        <div>
                            <div className='row-between-page'>
                                <div>
                                    <div>
                                        <label style={{ fontSize: 18 }}><b>Upload file ประกอบข้อมูล color</b></label>
                                    </div>
                                    <input type='file' name='loadfile' accept="image/png, image/jpg" onChange={handleFile} id='loadfile' />

                                    <Button onClick={Uploadfilecolor}>
                                        upload file
                                    </Button>
                                    <div>

                                        {jouranldata.uploadfilecolor != "" &&
                                            <div>

                                                <div className='row-page' style={{ marginLeft: 20 }}>
                                                    <label><b>ตัวอย่าง Product </b></label> <ViewImages urlImages={jouranldata.uploadfilecolor} />
                                                </div>
                                                <div style={{ margin: 0, padding: 0 }} className='row-page'>
                                                    {jouranldata.uploadfilecolor != "" ? <img src={host + '/images/files/' + jouranldata.uploadfilecolor} style={{ width: '30%' }} /> : <CiImageOn size={50} />}
                                                </div>
                                            </div>}
                                    </div>
                                </div>
                            </div>
                            <hr></hr>

                            <div style={{ padding: 10 }}>
                                <div style={{ marginBottom: 10 }} className='row-page'>
                                    <div style={{ marginRight: 10 }}>
                                        <input type='radio' name='color1' value={'color1'} onChange={(e) => onchangTypecolor(e)} />{" "}
                                        <label><b>Color 1</b></label>
                                    </div>
                                    <div style={{ marginRight: 10 }}>
                                        <input type='radio' name='color1' value={'color2'} onChange={(e) => onchangTypecolor(e)} />{" "}
                                        <label><b>Color 2</b></label>
                                    </div>
                                    <div style={{ marginRight: 10 }}>
                                        <input type='radio' name='color1' value={'color3'} onChange={(e) => onchangTypecolor(e)} />{" "}
                                        <label><b>Color 3</b></label>
                                    </div>
                                    <div style={{ marginRight: 10 }}>
                                        <input type='radio' name='color1' value={'color4'} onChange={(e) => onchangTypecolor(e)} />{" "}
                                        <label><b>Color 4</b></label>
                                    </div>
                                    <div style={{ marginRight: 10 }}>
                                        <input type='radio' name='color1' value={'color5'} onChange={(e) => onchangTypecolor(e)} />{" "}
                                        <label><b>Color 5</b></label>
                                    </div>
                                    <div style={{ marginRight: 10 }}>
                                        <input type='radio' name='color1' value={'color6'} onChange={(e) => onchangTypecolor(e)} />{" "}
                                        <label><b>Color 6</b></label>
                                    </div>
                                    <div style={{ marginRight: 10 }}>
                                        <input type='radio' name='color1' value={'color7'} onChange={(e) => onchangTypecolor(e)} />{" "}
                                        <label><b>Color 7</b></label>
                                    </div>
                                </div>
                                <div className='row-page'>
                                    <label style={{ fontSize: 16, marginRight: 10 }}><b>Color</b></label>
                                    <input type='text' name='textheader' style={{ width: 300 }} value={colorall.colorheader}
                                        onChange={(e) => setColorall({ ...colorall, colorheader: e.target.value })} />

                                    <div style={{ marginLeft: 10 }}>
                                        <Button size='sm' onClick={() => AddColorList()}>เพิ่มข้อมูล color</Button>{" "}
                                        <Button size='sm' onClick={() => EditColorHeader()}>แก้ไขข้อมูล</Button>{" "}
                                        <Button size='sm' variant="warning" onClick={() => removeAllbyType()}>ลบข้อมูล color</Button>
                                    </div>

                                </div>
                                <div>
                                    <div>
                                        <label>Remarks</label>
                                    </div>
                                    <textarea style={{ width: 350, height: 100 }}
                                        value={colorall.remark}
                                        onChange={(e) => setColorall({ ...colorall, remark: e.target.value })} />
                                </div>
                            </div>
                            <hr></hr>
                            {jourColorHeader.map((item, i) => (
                                <div>
                                    <Table striped bordered hover size="sm">
                                        <thead>
                                            <tr>
                                                <th style={{ width: '5%' }}># Number</th>
                                                <th style={{ width: '30%' }}>Color name</th>
                                                <th style={{ width: '40%' }}>Remark</th>
                                                <th style={{ width: '10%' }}>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                               
                                                <td><center>{item.number}</center></td>
                                                <td>{item.colorname}</td>
                                                <td>{item.remark}</td>
                                                <td>
                                                    <PopUpaddDetailcolors colorId={item.colorId} journalId={item.journalId} reloadingdata={reloadingdata} />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                    {loadingdetail == false && <Tablecolordetail colorId={item.colorId} />}
                                    <hr></hr>
                                </div>))}
                        </div>
                    </Tab>

                </Tabs>

            </div>

        </div>}
        </div>
    )
}

export default PrintingDetailpartb