import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    dataext: [],
    dataint: [],
    dataspray: [],
    datacopper: [],
    datallacq:[],
    productitem:[{
        sidetinext:"",
        sidetinint:"",
        com1tinext:"",
        com1tinint:"",
        com2tinext:"",
        com2tinint:"",
        activebom:"",
        auditdep:"",
        packtest:"",
        packer: "",
        refproduct: "",
        journalId:""}
    ]

}

export const productionSlice = createSlice({
    name: 'productionSlice',
    initialState,
    reducers: {
        additemExt: (state, action) => {
            state.dataext = action.payload;
        },
        additemInt: (state, action) => {
            state.dataint = action.payload;
        },
        additemSpray: (state, action) => {
            state.dataspray = action.payload;
        },
        additemCopper: (state, action) => {
            state.datacopper = action.payload;
        },
        additemLacq:(state, action)=>{
            state.datallacq=action.payload;
        },
        additemProductitem: (state, action) => {
            state.productitem = action.payload;
        }, 
        addsidetinext:(state, action)=>{
            state.productitem[0].sidetinext = action.payload;
        },
        addsidetinint:(state, action)=>{
            state.productitem[0].sidetinint = action.payload; 
        },
        addcom1tinext:(state, action)=>{
            state.productitem[0].com1tinext = action.payload; 
        },
        addcom1tinint:(state, action)=>{
            state.productitem[0].com1tinint = action.payload; 
        },
        addcom2tinext:(state, action)=>{
            state.productitem[0].com2tinext = action.payload;
        },
        addcom2tinint:(state, action)=>{
            state.productitem[0].com2tinint = action.payload;
        },
        addactivebom:(state, action)=>{
            state.productitem[0].activebom = action.payload;
        },
        addauditdep:(state, action)=>{
            state.productitem[0].auditdep = action.payload; 
        },
        addpacktest:(state, action)=>{
            state.productitem[0].packtest = action.payload; 
        },
        addpacker:(state, action)=>{
            state.productitem[0].packer = action.payload;
        },
        addrefproduct:(state, action)=>{
            state.productitem[0].refproduct = action.payload;
        },
        addIdjournal:(state,action) =>{
            state.productitem[0].journalId=action.payload;
        }  
        
    },
})

// Action creators are generated for each case reducer function
export const {additemExt,additemInt,additemSpray,additemCopper,additemProductitem,additemLacq,
    addsidetinext,addsidetinint,addcom1tinext,addcom1tinint,addcom2tinext,addcom2tinint,addactivebom,
    addauditdep,addpacktest,addpacker,addrefproduct,addIdjournal
} = productionSlice.actions

export default productionSlice.reducer