import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import FetchApi from '../../../customhooks/functionFetchApi';
import Showdetailjournal from '../../../form01/components/showdetailjournal';
// import Journaldetialpartb from '../../components/componentrdnewpartb/journaldetailpartb';
import Actionapprove from '../../components/componentapprove/actionapprove';
// import FG1Detailpartb from '../fg1Detailpartb';

import AllDetailpartb from '../../components/allDetailpartb';

import Fg1approveState from '../fg1approveState';
import Edit1stcoating from '../componentfg1/edit1stcoating';
import Edit2ndcoating from '../componentfg1/edit2ndcoating';
import Edit3rdcoating from '../componentfg1/edit3rdcoating';
import Edit4thcoating from '../componentfg1/edit4thcoating';
import Edit5thcoating from '../componentfg1/edit5thcoating';
import Edit6thcoating from '../componentfg1/edit6thcoating';
import Edit7thcoating from '../componentfg1/edit7thcoating';




const datalistbody = {
  sequence: "1st",
  type: "",
  side: "",
  color: "",
  fm: "",
  dfw: "",
  wfw: "",
  temp: "",
  speed: "",
  rubber: "",
  other: ""
}

function ViewError() {
  return <div className='border-box-center' style={{ height: 300 }}>
    <center> <label style={{ fontSize: 18 }}>ยังไม่มีข้อมูลในการ Update</label></center>
  </div>
}

function FormEditJournal({ jourId, codeid, stateflow, handleShow }) {

  const FetchApis = new FetchApi()
  const [key, setKey] = useState('jouranl');

  const [coating1st, setCoating1st] = useState([]);
  const [coating2nd, setCoating2nd] = useState([]);
  const [coating3rd, setCoating3rd] = useState([]);
  const [coating4th, setCoating4th] = useState([]);
  const [coating5th, setCoating5th] = useState([]);
  const [coating6th, setCoating6th] = useState([]);
  const [coating7th, setCoating7th] = useState([]);

  useEffect(() => {
    heandle_checkPartB();
  }, [])

  async function heandle_checkPartB() {
    await FetchApis.FethcGet(`/technical/techniaclByJournalId/${jourId}`).then((res) => {

      if (res.data.data.length > 1) {
        const datat1st = res.data.data.find((item) => item.sequence == '1st');
        setCoating1st(datat1st);
        const data2nd = res.data.data.find((item) => item.sequence == '2nd');
        setCoating2nd(data2nd);
        const data3rd = res.data.data.find((item) => item.sequence == '3rd');
        setCoating3rd(data3rd);
        const data4th = res.data.data.find((item) => item.sequence == '4th');
        setCoating4th(data4th);
        const data5th = res.data.data.find((item) => item.sequence == '5th');
        setCoating5th(data5th);
        const data6th = res.data.data.find((item) => item.sequence == '6th');
        setCoating6th(data6th);
        const data7th = res.data.data.find((item) => item.sequence == '7th');
        setCoating7th(data7th);
      }
    })
  }

  return (
    <div className='form-body-page'>
      <div className='end-flex-row'>
        <Fg1approveState idjournal={jourId} stateflow={stateflow} />
        <Actionapprove journalId={jourId} />
      </div>
      <form >
        <Tabs
          id="controlled-tab-example"
          activeKey={key}
          onSelect={(k) => setKey(k)}
          className="mb-3"
        >
          <Tab eventKey="jouranl" title="TECHNICAL DATA SHEET A" style={{ backgroundColor: 'aliceblue' }}>
            <Showdetailjournal id={jourId} />
          </Tab>
          <Tab eventKey="journaldetailpartb" title="TECHNICAL DATA SHEET B" style={{ backgroundColor: 'aliceblue' }}>
            {/* <Journaldetialpartb /> */}
            {/* <FG1Detailpartb journalId={jourId}/>/ */}
            <AllDetailpartb journalId={jourId} />
          </Tab>
          <Tab eventKey="rdaddnew1stcoating" title="เพิ่มข้อมูล ( 1 ST )" style={{ backgroundColor: 'aliceblue' }}>
            {/* {JSON.stringify(coating1st !="")} */}
            {coating1st != "" ? <Edit1stcoating coating1st={coating1st} /> : <div><ViewError /></div>}
          </Tab>
          <Tab eventKey="rdaddnew2ndcoating" title="เพิ่มข้อมูล( 2 ND )" style={{ backgroundColor: 'aliceblue' }}>
            {coating2nd != "" ? <Edit2ndcoating coating2nd={coating2nd} /> : <div><ViewError /></div>}
          </Tab>
          <Tab eventKey="rdaddnew3rdcoating" title="เพิ่มข้อมูล( 3 RD )" style={{ backgroundColor: 'aliceblue' }}>
            {coating3rd != "" ? <Edit3rdcoating coating3rd={coating3rd} /> : <div><ViewError /></div>}
          </Tab>
          <Tab eventKey="rdaddnew4thcoating" title="เพิ่มข้อมูล( 4 th )" style={{ backgroundColor: 'aliceblue' }}>
            {coating4th != "" ? <Edit4thcoating coating4th={coating4th} /> : <div><ViewError /></div>}
          </Tab>
          <Tab eventKey="rdaddnew5thcoating" title="เพิ่มข้อมูล( 5 th )" style={{ backgroundColor: 'aliceblue' }}>
            {coating5th != "" ? <Edit5thcoating coating5th={coating5th} /> : <div><ViewError /></div>}
          </Tab>
          <Tab eventKey="rdaddnew6thcoating" title="เพิ่มข้อมูล( 6 th )" style={{ backgroundColor: 'aliceblue' }}>
            {coating6th != "" ? <Edit6thcoating coating6th={coating6th} /> : <div><ViewError /></div>}
          </Tab>
          <Tab eventKey="rdaddnew7thcoating" title="เพิ่มข้อมูล( 7 th )" style={{ backgroundColor: 'aliceblue' }}>
            {coating7th != "" ? <Edit7thcoating coating7th={coating7th} /> : <div><ViewError /></div>}
          </Tab>

        </Tabs>
      </form>
    </div>
  )
}


const Updatepartb = ({ journalid, codeid, stateflow }) => {

  const values = [true, 'sm-down', 'md-down', 'lg-down', 'xl-down', 'xxl-down'];
  const [fullscreen, setFullscreen] = useState(true);
  const [show, setShow] = useState(false);

  function handleShow(value) {
    setShow(value);
  }

  // function refunction(value) {
  //   console.log(value)
  // }

  return (
    <div >
      <Button variant="warning" style={{ fontSize: 12 }} onClick={() => handleShow(true)} >จัดการเอกสาร</Button>
      <Modal show={show} fullscreen={fullscreen} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>แก้ไขข้อมูลเอกสาร ( Part B )</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <div className='end-flex-row'> 
       <RdApproveState idjournal={datavalues} stateflow={stateflow}/>
       </div> */}
          <div>
            <FormEditJournal jourId={journalid} codeid={codeid} stateflow={stateflow} handleShow={handleShow} />
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default Updatepartb